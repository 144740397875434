.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.surchargesTable {
    border: 1px solid #ededed;
}

.additionalSurchargesBtn {
    width: fit-content;
    font-size: 13px;
    font-weight: 400;
    font-family: sans-serif;
    padding: 10px;

    .plusIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        border: 1px dotted #188bf6;
        border-radius: 50%;
        margin-bottom: 3px;
        width: 25px;
        height: 25px;
    }
}

.actionCol {
    display: flex;
    gap: 8px;

    .actionBtn {
        background: none;
        border: none;

        &.edit {
            color: #188bf6
        }

        &.delete {
            color: red
        }
    }
}
@import "../../../../../../../../styles/theme.scss";

.root {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  padding: 20px 20px 0 20px;
}

.image {
  width: 90px;
  height: 90px;
  min-width: 90px;
  max-height: 90px;
  border-radius: 8px;
}

.name {
  font-size: 18px;
  color: $cBlack;
}

.name a {
  color: inherit;
  text-decoration: none;
}

.name a:hover {
  text-decoration: underline;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.infoRow {
  display: flex;
  gap: 6px;
  font-size: 13px;
  word-break: break-word;
  max-width: 195px;

  svg {
    margin-top: 3px;
  }
}

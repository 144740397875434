@import "../../../../../../../../styles/theme.scss";

.root {
  display: flex;

  .input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  .rightAdornment {
    width: 50px;
    border-radius: 0 8px 8px 0;
    background: none;
    border: 1px solid $cGray1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
  }
}

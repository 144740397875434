.warningBox {
  font-size: 17px;
  border: none;
  padding: 20px;
  background: #e6f2f8;
  border-radius: 5px;
  margin-bottom: 15px;
  border-left: 5px solid #047ec3;
  p {
    margin: 0;
  }
}

@import "../../../../../../styles/theme.scss";

.root {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  margin-top: 25px;
  border-bottom: 1px solid $cGray3;
  padding-bottom: 25px;
}
.importantInfo {
  margin-top: 10px;
}

@import "../../../../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border-radius: 4px;
  background-color: $cPrimaryDim2;

  ul {
    margin-bottom: 0;
    max-height: 250px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 4px;

    li {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}

.badges {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;

  div {
    background-color: $cPrimary;
    color: $cWhite;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 12px;

    button {
      background-color: $cWhite;
      width: 16px;
      height: 16px;
      border-radius: 3px;
      color: $cRed;
      border: none;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@import "./variables.scss";

$opensan: "Open Sans", sans-serif;
$lato: "Lato", sans-serif;
$login-font-color: #2c3e50;
$login-font-size: 16px;
$login-blue-color: #0075c0;
$login-title-size: 37px;
$white-color: #ffffff;
$black-color: #000000;
$blue: #188bf6;
$blue-hover: #217acd;
$main-bg: #e6eef2;
$yellow: #f2b675;
$error: #bf2828;

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.d-flex {
  display: flex !important;
}

.align-center {
  align-items: center !important;
}

.gap-2 {
  gap: 2px !important;
}

.gap-4 {
  gap: 4px !important;
}

.gap-8 {
  gap: 8px !important;
}

.gap-16 {
  gap: 16px !important;
}

.gap-24 {
  gap: 24px !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-red {
  color: #bf2828;
}

.text-orange {
  color: #ee971f;
}

.lock-unlock-icon {
  margin: auto;
  width: 24px;
  height: 22px;
}

.validate-modal {
  width: 535px !important;
}

* {
  margin: 0px;
  padding: 0px;
  outline: none;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

img {
  max-width: 100%;
}

body {
  font-family: "Lato", sans-serif;
  background: $main-bg;
  background-attachment: fixed;
  background-size: cover;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $lato;
  margin: 0px;
}

.f-left {
  float: left !important;
}

.wrapper {
  max-width: 1200px;
  margin: auto;
}

.m-h-container {
  min-height: 387px;
}

.no-display {
  display: none !important;
}

.no-float {
  float: none !important;
}

.no-visible {
  visibility: hidden !important;
}

.form-control,
input {
  border-width: 0px;
}

.full-width {
  width: 100% !important;
}

.half-width {
  width: 49% !important;
  float: left;
}

#content {
  padding-top: 72px;
}

.break-word-content,
.break-all {
  word-break: break-all !important;
}

.overflow-wrap {
  overflow-wrap: break-word !important;
}

.word-break {
  word-break: break-word !important;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.btn {
  border-width: 1px;
}

.gray-bg {
  background: #fafafa;
}

.scroll-xy {
  overflow-y: auto !important;
  overflow-x: auto !important;
}

.scroll-y {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.scroll-x {
  overflow-x: auto !important;
  overflow-y: hidden !important;
}

.no-scroll {
  overflow-x: inherit !important;
  overflow-y: inherit !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.static {
  position: static !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.vertical-align-top td {
  vertical-align: top;
}

.readOnlyField setting-input-outer,
.readOnlyField input {
  background: #f5f5f5 !important;
}

.h-40 {
  height: 40px !important;
}

.h-85 {
  height: 85px !important;
}

.h-50 {
  height: 50px !important;
}

.h-130 {
  height: 130px !important;
}

.m-h-100 {
  min-height: 100px !important;
}

.m-h-200 {
  min-height: 200px !important;
}

.m-h-300 {
  min-height: 300px !important;
}

.m-h-400 {
  min-height: 400px !important;
}

.m-h-500 {
  min-height: 500px !important;
}

.min-h-200 {
  min-height: 200px;
}

.h-unset {
  height: unset !important;
}

.min-w-1000 {
  min-width: 1000px;
}

.min-w-900 {
  min-width: 900px;
}

.min-w-850 {
  min-width: 850px;
}

.min-w-600 {
  min-width: 600px;
}

.min-w-275 {
  min-width: 275px;
}

.min-w-16 {
  min-width: 16px;
}

.fixed-height-302 {
  min-height: 302px;
}

.full-fixed-loader {
  position: fixed !important;
  z-index: 11 !important;
}

.no-margin-top {
  margin-top: 0px !important;
}

.no-margin-bottom {
  margin-bottom: 0px !important;
}

.no-margin-left {
  margin-left: 0px !important;
}

.no-margin-right {
  margin-right: 0px !important;
}

.no-padding {
  padding: 0px !important;
}

.no-margin {
  margin: 0px !important;
}

.no-record {
  font-size: 13px;
  text-align: center;
  padding: 5px;
  float: left;
  width: 100%;
}

.no-record:hover {
  background: #ffffff;
}

.no-padding-right {
  padding-right: 0px !important;
}

.no-padding-top {
  padding-top: 0px !important;
}

.no-padding-bottom {
  padding-bottom: 0px !important;
}

.no-padding-left {
  padding-left: 0px !important;
}

.no-border {
  border: none !important;
}

.no-border-left {
  border-left: none !important;
}

.no-border-right {
  border: right !important;
}

.no-border-bottom {
  border: bottom !important;
}

.no-border-top {
  border: top !important;
}

.no-bg {
  background: none !important;
}

.no-width {
  width: auto !important;
  min-width: inherit !important;
}

.no-list {
  list-style: none;
}

.-m-t-5 {
  margin-top: -5px !important;
}

.m-t-0 {
  margin-top: 0px !important;
}

.m-t-1 {
  margin-top: 1px !important;
}

.m-t-2 {
  margin-top: 2px !important;
}

.m-t-3 {
  margin-top: 3px !important;
}

.m-t-4 {
  margin-top: 4px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-7 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-17 {
  margin-top: 17px !important;
}

.m-t-18 {
  margin-top: 18px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-45 {
  margin-top: 45px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-t-55 {
  margin-top: 55px !important;
}

.m-t-60 {
  margin-top: 60px !important;
}

.m-t-65 {
  margin-top: 65px !important;
}

.m-t-200 {
  margin-top: 200px !important;
}

.-m-b-5 {
  margin-bottom: -5px !important;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-b-1 {
  margin-bottom: 1px !important;
}

.m-b-2 {
  margin-bottom: 2px !important;
}

.m-b-3 {
  margin-bottom: 3px !important;
}

.m-b-4 {
  margin-bottom: 4px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-8 {
  margin-bottom: 8px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-23 {
  margin-bottom: 23px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-35 {
  margin-bottom: 35px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-b-45 {
  margin-bottom: 45px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.m-b-55 {
  margin-bottom: 55px !important;
}

.m-b-60 {
  margin-bottom: 60px !important;
}

.m-b-65 {
  margin-bottom: 65px !important;
}

.-m-l-5 {
  margin-left: -5px !important;
}

.m-l-0 {
  margin-left: 0px !important;
}

.m-l-1 {
  margin-left: 1px !important;
}

.m-l-2 {
  margin-left: 2px !important;
}

.m-l-3 {
  margin-left: 3px !important;
}

.m-l-4 {
  margin-left: 4px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-l-35 {
  margin-left: 35px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.m-l-45 {
  margin-left: 45px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

.m-l-55 {
  margin-left: 55px !important;
}

.m-l-60 {
  margin-left: 60px !important;
}

.m-l-65 {
  margin-left: 65px !important;
}

.-m-r-5 {
  margin-right: -5px !important;
}

.m-r-0 {
  margin-right: 0px !important;
}

.m-r-1 {
  margin-right: 1px !important;
}

.m-r-2 {
  margin-right: 2px !important;
}

.m-r-3 {
  margin-right: 3px !important;
}

.m-r-4 {
  margin-right: 4px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-r-25 {
  margin-right: 25px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.m-r-35 {
  margin-right: 35px !important;
}

.m-r-40 {
  margin-right: 40px !important;
}

.m-r-45 {
  margin-right: 45px !important;
}

.m-r-50 {
  margin-right: 50px !important;
}

.m-r-55 {
  margin-right: 55px !important;
}

.m-r-60 {
  margin-right: 60px !important;
}

.m-r-65 {
  margin-right: 65px !important;
}

.m-r-auto {
  margin-right: auto !important;
}

.p5 {
  padding: 5px !important;
}

.p10 {
  padding: 10px !important;
}

.p15 {
  padding: 15px !important;
}

.p20 {
  padding: 20px !important;
}

.p25 {
  padding: 25px !important;
}

.p30 {
  padding: 30px !important;
}

.p35 {
  padding: 35px !important;
}

.p2030 {
  padding: 20px 30px !important;
}

.p-t-0 {
  padding-top: 0px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

.p-t-55 {
  padding-top: 55px !important;
}

.p-t-60 {
  padding-top: 60px !important;
}

.p-t-65 {
  padding-top: 65px !important;
}

.p-b-0 {
  padding-bottom: 0px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

.p-b-55 {
  padding-bottom: 55px !important;
}

.p-b-60 {
  padding-bottom: 60px !important;
}

.p-b-65 {
  padding-bottom: 65px !important;
}

.p-l-0 {
  padding-left: 0px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.p-l-35 {
  padding-left: 35px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}

.p-l-45 {
  padding-left: 45px !important;
}

.p-l-50 {
  padding-left: 50px !important;
}

.p-l-55 {
  padding-left: 55px !important;
}

.p-l-60 {
  padding-left: 60px !important;
}

.p-l-65 {
  padding-left: 65px !important;
}

.p-r-0 {
  padding-right: 0px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.p-r-35 {
  padding-right: 35px !important;
}

.p-r-40 {
  padding-right: 40px !important;
}

.p-r-45 {
  padding-right: 45px !important;
}

.p-r-50 {
  padding-right: 50px !important;
}

.p-r-55 {
  padding-right: 55px !important;
}

.p-r-60 {
  padding-right: 60px !important;
}

.p-r-65 {
  padding-right: 65px !important;
}

.color-dark-blue {
  color: $blue-hover;
}

.guest {
  background: $white-color !important;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  padding-bottom: 158px;
  overflow-y: auto;
  overflow-x: hidden;
}

body.guest-body {
  background: #ffffff;
}

body.guest-body .guest {
  position: inherit;
}

.touchMDHeader .mdWrapper {
  width: 450px;
  margin: auto;
}

.touchMDHeader .touchMDlogo {
  float: right;
  padding: 11px;
  background: #000000;
  @include border-radius(5px);
}

.touchMDHeader .touchMDlogo img {
  height: 23px;
}

.touchMDHeader .candelaLogo {
  float: right;
  background: #000000;
  @include border-radius(5px);
}

.touchMDHeader .candelaLogo img {
  height: 50px;
}

.touchMDHeader .header-right {
  margin-top: -50px;
}

.blue-btn {
  color: $white-color;
  background: $blue;
  display: inline-block;
  @include border-radius(3px);
  border: 1px solid $blue;
  margin-left: 5px;
  padding: 0px 20px;
  height: 30px;
  line-height: 28px;
  font-size: 12px;
  font-weight: normal;
  text-transform: inherit;

  &:hover {
    background: $blue-hover;
  }
}

.field-outer select {
  background: url(/../images/arrow.png) no-repeat right 10px center;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  text-indent: 0.01px;
  font-size: 13px;
  padding-right: 27px;
}

.dash-box {
  background: $white-color;
  float: left;
  width: 100%;
  @include border-radius(3px);
  @include box-shadow(0, 1px, 2px, 0, rgba(0, 0, 0, 0.15));
  padding: 15px;
  margin-bottom: 10px;
  position: relative;
}

.dash-box-title {
  font-size: 11px;
  text-transform: uppercase;
  float: left;
  width: 100%;
  color: #404040;
}

.btn-default:hover {
  color: $white-color;
  background-color: $blue;
  border-color: $blue;
}

.inner-wrapper,
.setting-wrapper {
  max-width: 1400px;
}

.client-profile-container {
  max-width: 1800px;
}

/************************* setting-menus ******************************/

.setting-left-menu {
  width: 16%;
  float: left;

  @media screen and (max-width: 767px) {
    padding-top: 20px;
  }

  .setting-title {
    font-size: 22px;
    float: left;
    width: 100%;
    color: #404040;
    margin-bottom: 20px;
    font-family: $lato;
    font-weight: normal;
  }
}

ul.new-setting-tabs {
  height: auto;
  display: block;
  margin-bottom: 15px;
  float: left;
  width: 100%;
  padding: 0px;

  &.salestab {
    margin-top: -22px;

    svg {
      top: 0px;
      margin-left: 0px;
      margin-right: 7px;
      display: inline-block;
    }
  }

  > li.no-child a {
    color: #404040;
  }

  > li {
    display: block;
    width: 100%;
    vertical-align: top;

    > a {
      display: block;
      width: auto;
      padding: 18px 0px;
      color: #667680;
      font-size: 15px;
      text-align: left;
      line-height: 14px;
      font-family: $lato;
      font-weight: normal;
      cursor: pointer;

      i,
      svg {
        font-size: 15px;
        font-weight: 600;
        display: inline-block;
        top: 1px;
        position: relative;
        margin-left: 3px;
        color: #0472b3;
      }
    }

    > a.active-menu {
      color: #404040;
    }

    ul.setting-submenu {
      display: block;

      > li {
        display: block;
        margin-bottom: 10px;

        a {
          display: block;
          padding: 8px 15px;
          color: #667680;
          font-size: 15px;
          text-align: left;
          font-family: $lato;
          font-weight: normal;
        }

        a.sel-submenu,
        a:hover {
          background: #dae7ee;
          @include border-radius(3px);
          color: $blue;
        }
      }
    }

    .new-setting-tabs {
      display: none;
    }

    .new-setting-tabs.new-setting-tabs-inner {
      display: block;
      padding-left: 25px;

      .new-setting-tabs-li-inner {
        text-transform: capitalize;
        cursor: pointer;
      }
    }
  }

  li:last-child {
    margin: 0px !important;
  }
}

.setting-setion,
.juvly-section {
  font-size: 14px;
  font-family: "Lato", sans-serif;
  color: #667680;
  font-weight: normal;
  background: $white-color;
  float: right;
  width: 81%;
  @include border-radius(5px);
  @include box-shadow(0, 1px, 2px, 0, rgba(0, 0, 0, 0.15));
  min-height: 450px;
  margin-bottom: 50px;
  position: relative;
}

.setting-title,
.juvly-title {
  font-size: 22px;
  float: left;
  width: 100%;
  color: #404040;
  margin-bottom: 20px;
  font-family: $lato;
  font-weight: normal;
  position: relative;
}

.settings-subtitle,
.juvly-subtitle {
  color: #667680;
  float: left;
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 40px;
  font-family: $lato;
}

.setting-field-outer {
  display: block;
  margin-bottom: 30px;
  float: left;
  width: 100%;
}

.setting-input-outer {
  display: block;
  float: left;
  width: 100%;
  position: relative;
  @include border-radius(0px);
}

.new-field-label {
  font-size: 10px;
  color: #667680;
  display: block;
  text-transform: uppercase;
  margin-bottom: 5px;
  float: left;
  width: 100%;
  font-family: $lato;
  font-weight: normal;
}

.setting-require,
.required {
  font-size: 10px;
  display: inline-block;
  color: #d8293e;
  line-height: 13px;
  vertical-align: top;
}

.setting-input-box {
  font-size: 14px;
  color: $blue;
  display: block;
  border: none;
  border-bottom: 1px solid #bfc9ce;
  background: none;
  width: 100%;
  font-weight: normal;
  padding-bottom: 5px;
  min-height: 26px;
  overflow: hidden;
  outline: none;
  font-family: $lato;
  @include border-radius(0px);
}

div.setting-input-box {
  color: #404040;
}

.setting-textarea-box {
  font-size: 14px;
  color: $blue;
  display: block;
  border: none;
  border-bottom: 1px solid #bfc9ce;
  background: none;
  width: 100%;
  font-weight: normal;
  padding-bottom: 10px;
  min-height: 85px;
  outline: none;
  resize: none;
  font-family: $lato;
  overflow: hidden;
  @include border-radius(0px);
}

div.setting-textarea-box {
  padding-bottom: 15px;
  min-height: 26px;
  font-family: $lato;
}

.setting-custom-switch {
  padding: 0px;
  font-size: 13px;
  display: block;
  border: none;
  text-align: left;
  font-weight: normal;
  color: #667680;
}

.setting-switch {
  border: none;
  background: none;
  float: left;
  font-size: 12px;
  margin-right: 0px;
  position: relative;
  display: inline-block;
  min-height: initial;
  height: 22px;
  width: 42px;
  background: none;
  vertical-align: top;
  margin: 1px 0px 0px 0px;
  max-width: inherit;

  input {
    visibility: hidden;
  }
}

.setting-slider {
  @include border-radius(34px);
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @include transition(all, 0.4s, ease);
  background: #667680;
  border: 1px solid #667680;
}

.setting-slider:before {
  background-color: $white-color;
  content: "";
  text-indent: 21px;
  font-weight: normal;
  color: #aaa;
  font-size: 11px;
  line-height: 18px;
  height: 16px;
  width: 16px;
  @include border-radius(30px);
  position: absolute;
  @include transition(all, 0.4s, ease);
  left: 2px;
  bottom: 2px;
}

.setting-custom-switch-input {
  margin-top: -7px;
  display: none;
}

input:checked + .setting-slider {
  background-color: #188bf6;
  border-color: #188bf6;
}

input:focus + .setting-slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .setting-slider:before {
  @include transform(translateX(20px));
  background: $white-color;
  text-indent: -21px;
  font-weight: normal;
  color: #2196f3;
  font-size: 11px;
  line-height: 18px;
}

.switch-text {
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
  margin-right: 18px;
}

.new-blue-btn {
  @include box-shadow(0, 1px, 2px, 0, rgba(0, 0, 0, 0.15));
  border: none;
  padding: 4px 17px 4px;
  height: 32px;
  font-size: 14px;
  color: $white-color !important;
  background-color: $blue !important;
  @include border-radius(3px);
  display: inline-block;
  vertical-align: top;
  min-width: 103px;
  text-align: center;
  margin-left: 15px;
  text-decoration: none !important;
  font-family: $lato;
  line-height: 24px;
}

.new-blue-btn.new-blue-btn-xs {
  padding: 0 8px 1px;
  height: auto;
  font-size: 10px;
  min-width: auto;
  line-height: 20px;
}

.new-blue-btn.new-blue-s {
  padding: 4px 9px 4px;
}

.new-blue-btn-2 {
  border: none;
  padding: 4px 17px 4px;
  height: 32px;
  font-size: 14px;
  color: $white-color;
  background-color: $blue;
  @include border-radius(3px);
  display: inline-block;
  vertical-align: top;
  min-width: 103px;
  text-align: center;
  margin-left: 15px;
  text-decoration: none !important;
  font-family: $lato;
  line-height: 24px;
}

.line-btn,
.new-line-btn {
  background: none;
  border: 1px solid $blue;
  color: $blue;
  font-size: 14px;
  font-family: $lato;
  font-weight: normal;
  height: 30px;
  line-height: inherit;
  padding: 4px 17px 4px;
  text-transform: inherit;
  @include border-radius(3px);
  min-width: 103px;
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
  text-decoration: none !important;
  overflow: hidden;
  position: relative;

  svg {
    position: absolute;
    right: 8px !important;
    top: 8px !important;
  }

  &:hover {
    background: $blue;
    color: $white-color;
  }
}

.new-line-btn {
  height: 32px;
  padding: 5px 17px 4px;
}

.line-btn#dropdownMenu1 {
  padding-right: 20px !important;
  font-size: 12px;
}

.footer-static {
  border-top: 1px solid #ececec;
  display: block;
  padding: 15px;
  float: left;
  width: 100%;
}

.footer-sticky {
  display: block;
  padding: 15px;
  float: left;
  width: 100%;
  background: $white-color;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.p-text {
  font-size: 14px;
  font-family: "Lato", sans-serif;
  color: #667680;
  display: block;
}

input[type="radio"],
.radio input[type="radio"],
.radio-inline input[type="radio"],
input[type="checkbox"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  width: 13px;
  height: 13px;
  border: 1px solid #dcdcdc;
  @include border-radius(1px);
  background: white;
  outline: none !important;
  cursor: pointer;
}

input[type="radio"] {
  @include border-radius(20px);
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="checkbox"]:checked::after,
.checkbox input[type="checkbox"]:checked::after,
.checkbox-inline input[type="checkbox"]:checked::after {
  content: url(/../images/new-check.png);
  display: block;
  position: absolute;
  top: -2px !important;
  left: 1px;
  outline: none;
}

input[type="radio"]:checked::after,
.radio input[type="radio"]:checked::after,
.radio-inline input[type="radio"]:checked::after {
  content: "";
  display: block;
  position: relative;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  @include border-radius(1em);
  background: #666;
  outline: none;
}

input[type="radio"]:checked::after,
.radio input[type="radio"]:checked::after,
.radio-inline input[type="radio"]:checked::after {
  background-color: $blue;
  width: 11px;
  height: 11px;
}

input[type="radio"],
.radio input[type="radio"],
.radio-inline input[type="radio"],
input[type="checkbox"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  width: 17px;
  height: 17px;
  font-size: 15px;
}

input.customRadio {
  width: 20px;
  height: 20px;
  border: 1px solid #777777;
  @include border-radius(1px);
  margin-right: 7px;
  display: inline-block;
  vertical-align: top;
  background-size: 10px;
  opacity: 0.3;
  filter: alpha(opacity=30);
}

input.customRadio.green {
  background-image: url(/../images/Mcheck.png);
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ffffff;
}

input.customRadio.green:checked {
  background-image: url(/../images/Mcheck-hover.png);
  background-repeat: no-repeat;
  background-position: center;
  background-color: #7bd2a8;
  border-color: #7bd2a8;
  opacity: 1;
  filter: alpha(opacity=100);
}

input.customRadio.green:checked::after {
  display: none;
}

input.customRadio.red {
  background-image: url(/../images/Mclose.png);
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ffffff;
}

input.customRadio.red:checked {
  background-image: url(/../images/Mclose-hover.png);
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ef8685;
  border-color: #ef8685;
  opacity: 1;
  filter: alpha(opacity=100);
}

input.customRadio:checked::after {
  background: none;
}

.customRadioOuter {
  display: flex;

  input {
    min-width: 20px;
  }

  label {
    margin-top: 5px;
  }
}

.radio-outer {
  display: block;
  margin-bottom: 15px;
  float: left;
  width: 100%;

  input {
    margin-top: 15px;
    float: left;
  }

  label {
    float: left;
    background: none;
    padding: 14px 23px 14px 7px;
    width: auto !important;
    font-weight: normal;
    display: inline-block;
    margin-bottom: 5px;
  }
}

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
  outline: none;

  option:disabled {
    cursor: not-allowed !important;
  }
}

select::-ms-expand {
  display: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
}

.setting-select-box {
  border: none;
  font-size: 14px;
  color: $blue;
  display: block;
  border-bottom: 1px solid #bfc9ce;
  width: 100%;
  font-weight: normal;
  padding-bottom: 3px;
  min-height: 26px;
  overflow: hidden;
  outline: none;
  padding-right: 15px;
  @include border-radius(0px);
  background: url(/../images/new-down-arrow.png) no-repeat right 9px;
}

.setting-search-outer {
  width: 100%;
  float: left;
  padding: 8px;
  min-height: 48px;
  position: relative;
}

.search-bg {
  min-width: 200px;
  border: none;
  background: #f2f6f7;
  height: 32px;
  display: block;
  @include border-radius(3px);
  padding: 0px !important;
  position: relative;

  svg,
  i {
    display: table-cell;
    font-size: 14px;
    color: #aaa;
    margin-left: 10px;
    vertical-align: middle;
    width: 33px;
    position: absolute;
    top: 9px;
  }

  .setting-search-input {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
    background: none;
    height: 30px;
    outline: none;
    padding-left: 33px;
  }
}

.table-responsive {
  float: left;
  width: 100%;
  overflow-y: hidden;
}

.table-updated {
  width: 100%;
  background: #ffffff;

  .table-updated-thead {
    background: #daedf7;
    text-transform: uppercase;

    .table-updated-th {
      border-left: 1px dotted #9db7c6;
      font-size: 12px;
      font-weight: 100;
      height: 44px;
      vertical-align: middle;
      border-top: none;
    }

    .table-updated-th:first-child {
      border-left: none !important;
    }

    .single-line-text {
      white-space: nowrap;
    }
  }

  .table-updated-tr {
    .table-updated-td {
      padding: 11px 10px;
      color: #77858e;
      text-decoration: none;
      font-weight: 400;
      font-size: 13px;

      .show-hide-btn {
        visibility: hidden;
        font-size: 12px;
        height: 28px;

        button {
          background: none;
          border: 1px solid $blue;
          color: $blue;
          font-size: 14px;
          font-family: $lato;
          font-weight: normal;
          height: 30px;
          line-height: inherit;
          padding: 4px 17px 4px;
          text-transform: inherit;
          @include border-radius(3px);
          display: inline-block;
          vertical-align: top;
          margin-left: 5px;
          text-decoration: none !important;
          overflow: hidden;
          position: relative;
        }
      }
    }

    .table-updated-td:first-child {
      border-left: none !important;
    }

    &:hover {
      background: #f7fbfd;
      cursor: pointer;

      .row-icon {
        display: inline-block;
      }

      .table-btn {
        visibility: visible;
      }

      .show-hide-btn {
        visibility: visible;
      }
    }
  }
}

.table-updated-th,
.table-updated-td {
  padding: 10px;
  font-weight: normal;
  border-top: 1px solid #e6e9eb;
  border-left: 1px dotted #e6e9eb;
  vertical-align: middle;
}

.table-fixed {
  position: relative;

  thead {
    position: sticky;

    tr {
      width: 100%;
      overflow-y: scroll;
    }
  }
}

.table-fixed thead tr::-webkit-scrollbar {
  -moz-opacity: 0;
  filter: alpha(opacity=0);
  -khtml-opacity: 0;
  visibility: hidden;
  opacity: 0;
}

.table-fixed thead tr::-moz-scrollbar {
  -moz-opacity: 0;
  filter: alpha(opacity=0);
  -khtml-opacity: 0;
  visibility: hidden;
  opacity: 0;
}

.table-fixed tbody {
  height: 230px;
  overflow-y: auto;
  width: 100%;
  float: left;
}

.no-hover tbody tr:hover {
  background: #ffffff;
  cursor: default;
}

.no-hover thead .table-updated-tr:hover {
  background: #daedf7;
  cursor: default;
}

.no-td-border {
  td,
  th {
    border-left: none !important;
  }
}

.table-fixed tbody td,
.table-fixed thead > tr > th {
  float: left;
  border-bottom-width: 0;
  position: static;
  display: table-cell;
}

.table-fixed tr {
  float: left;
  width: 100%;
}

.table-fixed th {
  height: 41px;
}

.row-icon {
  display: none;
  color: #777777;
  font-size: 13px;
  margin-right: 10px;
}

.vertical-top .table-updated-td {
  vertical-align: top;
}

.setting-table {
  border-bottom: 1px solid #e6e9eb;
  font-family: "Lato", sans-serif;
}

.td-clinic-name {
  font-weight: 400;
  font-size: 14px;
  color: #667680;
}

.setting-table .table-updated-th,
.setting-table .table-updated-td {
  word-break: inherit;
}

.setting-table,
.juvly-table,
.table-updated {
  border-bottom: 1px solid #e6e9eb;
  font-family: $lato;

  .table-updated-thead {
    background: #daedf7;
    text-transform: uppercase;

    .table-updated-th {
      font-size: 12px;
      font-weight: normal;
      min-height: 22px;
      height: auto;
      vertical-align: middle;
      padding: 4px 10px 4px 15px;
      //padding: 10px 5px 10px 8px;
      text-transform: initial;
      position: relative;
      color: #404040;

      &.sorting i {
        background-size: 7px 12px;
        top: 50%;
        margin-top: -9px;
      }
    }
  }

  .user-log-table-updated-th {
    @extend .table-updated-th;
    min-height: 48px;
    padding: 0px 10px 4px 15px;
  }

  .table-updated-tr {
    .table-updated-td {
      padding: 11px 10px 11px 15px;
      //padding: 10px 5px 10px 8px;
      font-size: 14px;
      color: #667680;
    }
  }
}

.Questionnaire-name {
  position: relative !important;
  padding: 15px 10px 15px 35px !important;
}

.drag-dots {
  width: 5px;
  height: 17px;
  display: inline-block;
  background: url(/../images/three-dots.png) no-repeat center;
  position: absolute;
  left: 15px;
  top: 50%;
  margin-top: -8px;
}

input.new-check[type="checkbox"],
.setting-field-outer input.new-check[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  width: 17px;
  height: 17px;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  margin-right: 8px;
  margin-top: 0px;
  position: relative;
}

input.new-check[type="checkbox"]:checked::after,
.setting-field-outer input[type="checkbox"]:checked::after {
  content: url(/../images/new-check.png);
  display: block;
  position: absolute;
  top: -1px !important;
  left: 1px;
  outline: none;
  vertical-align: top;
}

input.new-check[type="radio"]:checked::after,
.setting-field-outer input.new-check[type="radio"]:checked::after {
  content: "";
  display: block;
  position: relative;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  @include border-radius(1em);
  background: #666;
  outline: none;
}

.new-white-btn {
  @include box-shadow(0, 1px, 2px, 0, rgba(0, 0, 0, 0.15));
  border: none;
  padding: 4px 17px 4px;
  height: 32px;
  font-size: 14px;
  color: $blue;
  background-color: #fff;
  @include border-radius(3px);
  display: inline-block;
  vertical-align: top;
  min-width: 103px;
  text-align: center;
  text-decoration: none !important;
  font-family: "Lato", sans-serif;
  line-height: 24px;
}

.new-red-btn {
  @include box-shadow(0, 1px, 2px, 0, rgba(0, 0, 0, 0.15));
  border: none;
  padding: 4px 17px 4px;
  height: 32px;
  font-size: 14px;
  color: #ffffff;
  background-color: #d0021b !important;
  @include border-radius(3px);
  display: inline-block;
  vertical-align: top;
  min-width: 103px;
  text-align: center;
  text-decoration: none !important;
  font-family: "Lato", sans-serif;
  line-height: 24px;
}

.new-yellow-btn {
  @include box-shadow(0, 1px, 2px, 0, rgba(0, 0, 0, 0.15));
  border: none;
  padding: 4px 17px 4px;
  height: 32px;
  font-size: 14px;
  color: #ffffff;
  background-color: #f39c12 !important;
  @include border-radius(3px);
  display: inline-block;
  vertical-align: top;
  min-width: 103px;
  text-align: center;
  text-decoration: none !important;
  font-family: "Lato", sans-serif;
  line-height: 24px;
  margin-left: 15px;
}

.new-green-btn {
  @include box-shadow(0, 1px, 2px, 0, rgba(0, 0, 0, 0.15));
  border: none;
  padding: 4px 17px 4px;
  height: 32px;
  font-size: 14px;
  color: #ffffff !important;
  background-color: #5cb85c !important;
  @include border-radius(3px);
  display: inline-block;
  vertical-align: top;
  min-width: 103px;
  text-align: center;
  text-decoration: none !important;
  font-family: "Lato", sans-serif;
  line-height: 24px;
  margin-left: 15px;
}

.disable {
  @include opacity(0.5);
  cursor: not-allowed !important;
}

.setting-table {
  border-bottom: 1px solid #e6e9eb;
  font-family: "Lato", sans-serif;
}

.bg-light-blue {
  background: #f7fbfd;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  padding: 35px;
  float: left;
  width: 100%;
}

.setting-container,
.juvly-container {
  padding: 35px 50px;
  float: left;
  width: 100%;
}

.setting-container-2,
.juvly-container-2 {
  padding: 16px 19px;
  float: left;
  width: 100%;
}

.bg-white-scetion {
  padding: 35px;
}

.easy-link {
  color: $blue;
  text-decoration: none !important;
  padding: 6px 10px;
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
  border: none;
  background: none;

  &:hover {
    text-decoration: underline !important;
  }
}

td .easy-link {
  display: inline-flex;

  svg {
    margin-top: 2px;
  }
}

.add-btn {
  font-size: 13px;
  color: $blue;
  text-decoration: none !important;

  span {
    font-size: 25px;
    display: inline-block;
    vertical-align: top;
    line-height: 16px;
  }
}

.basic-checkbox-outer {
  float: left;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.basic-form-text {
  font-size: 14px;
  float: left;
  font-weight: normal;
  margin-right: 10px;
}

input.basic-form-checkbox {
  float: left;
  margin-right: 10px;
  margin-top: 3px;
}

input.basic-form-checkbox[type="radio"]:checked::after {
  content: "";
  display: block;
  position: relative;
  top: 2px;
  left: 2px;
  width: 9px;
  height: 9px;
  @include border-radius(1em);
  background: $blue;
  outline: none;
}

input.basic-form-checkbox[type="radio"] {
  width: 15px;
  height: 15px;
}

.choose-file {
  height: 25px;
  padding: 4px 10px;
  min-width: inherit;
  margin-top: -4px;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 4px;
  line-height: 15px;
}

.dropzone-filename {
  span {
    width: 43%;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 0px;
    white-space: nowrap;
    padding-left: 3px;
  }

  .dz-message {
    display: none;
  }
}

.help-icon {
  width: 20px;
  height: 20px;
  text-align: center;
  @include border-radius(50px);
  border: 1px solid #2a8fc9;
  color: #2a8fc9;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  vertical-align: top;
  margin-top: 6px;
  margin-left: 10px;
}

.instruction-subtitle {
  color: #667680;
  float: left;
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 15px;
}

.switch-accordian-outer {
  display: block;
  float: left;
  width: 100%;

  .switch-accordian-row {
    padding: 17px 80px 17px 35px;
    color: #222222;
    font-size: 20px;
    display: block;
    border-top: 1px solid #ececec;
    float: left;
    width: 100%;
    position: relative;
  }

  .setting-switch {
    position: absolute;
    right: 30px;
    top: 19px;
  }
}

.setting-variable {
  font-size: 12px;
  line-height: 2;
}

.displayBlock {
  display: block !important;
}

.overlay {
  opacity: 0.5;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000;
}

.new-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  top: 0px;
  float: left;
  font-size: 13px;
  text-align: center;
  display: none;
  left: 0px;
  right: 0px;
  z-index: 9999;

  .loader-outer {
    position: absolute;
    margin-top: 40px;
    margin-left: -20px;
    z-index: 100;
    text-align: center;
    width: 100%;

    img {
      display: inline-block;
      text-align: center;
      margin-bottom: 18px;
      margin-top: 27px;
      height: 62px;
    }
  }
}

.draggable {
  margin: 10px;
}

.dragged {
  opacity: 0.7;
}

.placeholder {
  opacity: 0.5;
}

.row {
  clear: both;
}

.mce-tinymce {
  @include box-shadow-inherit;
}

.mce-menubar {
  border: none !important;
}

textarea.setting-input-box {
  height: 26px;
  border: none;
  border-bottom: 1px solid #cccccc;
  resize: none;
  display: block;
  background: none;
}

.continer-min-h {
  min-height: 487px;
}

.user-profile-img {
  width: 30px;
  height: 30px;
  display: inline-block;
  @include border-radius(50px);
  background: #eeeeee;
  overflow: hidden;
  margin-right: 5px;
  background-image: url(/../images/user.png);
  vertical-align: middle;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.sorting {
  cursor: pointer;

  i {
    font-size: 12px;
    margin-top: 0px;
    right: 3px;
    width: 11px;
    height: 19px;
    position: absolute;
    top: 13px;
    color: #667680;
    background-position: right center;
    background-repeat: no-repeat;
  }

  i.gray-gray {
    background-image: url(/../images/gray-gray.png);
  }

  i.blue-gray {
    background-image: url(/../images/blue-gray.png);
  }

  i.gray-blue {
    background-image: url(/../images/gray-blue.png);
  }

  i.blue-blue {
    background-image: url(/../images/blue-blue.png);
  }
}

.user-first-name {
  border-left: none !important;
  padding-left: 0px !important;
}

.crossIcon {
  top: -29px;
  right: -28px;
  position: relative;
}

.setting-text {
  font-size: 14px;
  font-family: "Lato", sans-serif;
  color: #667680;
  font-weight: normal;
  margin: 0px;
}

.border-right {
  border-right: 1px solid #ececec;
}

.border-left {
  border-left: 1px solid #ececec;
}

.border-top {
  border-top: 1px solid #ececec;
}

.border-bottom {
  border-bottom: 1px solid #ececec;
}

.dropdown-menu {
  padding: 0px;

  li {
    a {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: normal;
      line-height: 1.42857143;
      color: #7b8a8b;
      white-space: nowrap;
      font-size: 13px;

      &:hover,
      &.active {
        text-decoration: none;
        color: #ffffff;
        background-color: $blue;
      }

      &:focus {
        text-decoration: none;
        color: #ffffff;
        background-color: $blue;
      }
    }
  }
}

.add-field-list {
  position: absolute;
  right: 200px;
  padding: 15px;
  margin-left: -130px !important;
}

.add-round-btn {
  font-size: 13px;
  color: $blue;
  text-decoration: none !important;
  position: absolute;
  right: 0px;
  top: 10px;
  border: 1px solid $blue;
  width: 20px;
  height: 20px;
  @include border-radius(50px);
  text-align: center;
  background: #ffffff;

  span {
    font-size: 21px;
    display: inline-block;
    vertical-align: top;
    line-height: 16px;
  }
}

.relative {
  position: relative;
}

.static-upload-file {
  position: relative !important;
  left: 0px !important;
  top: 0px !important;

  > div {
    padding: 20px !important;

    img {
      @include opacity(0.3);
    }
  }
}

.file-upload {
  > div {
    padding: 20px !important;
  }

  img {
    @include opacity(0.3);
  }
}

.go-back-arrow {
  background-image: url(/../images/back-arrow.png);
  background-repeat: no-repeat;
  background-position: center;
  float: left;
  width: 20px;
  height: 30px;
  margin-right: 11px;
}

.comfirmation-msg {
  width: 100%;
  display: block;
  clear: both;
  text-align: center;
  margin: 200px 0px;
}

.search-bg.search-right {
  width: 200px;
  float: right;
}

.search svg {
  top: 8px;
}

.table-btn {
  visibility: hidden;
}

.user-name {
  position: relative;
  padding-left: 40px;

  .user-profile-img {
    position: absolute;
    left: 0px;
    top: -5px;
  }

  span {
    @include ellipsis();
    max-width: 170px;
    display: inline-block;
  }
}

.dashboard-bal {
  color: #667680;
  font-size: 20px;
  font-weight: bold;
  display: block;
}

.relative-pic {
  position: relative !important;
  left: 0px !important;
}

.proQuesAccordionOuter,
.accordion-table {
  float: none;
  width: 100%;
  border: 1px solid #ececec;
  border-top: none;
  display: table;

  div.accordian-section {
    padding: 13px;
    border-top: 1px solid #ececec;
    border-left: 1px dotted #e6e9eb;
    float: none;
    color: #667680;
    display: table-cell;
    vertical-align: top;

    > &:first-child {
      padding-left: 20px;
    }
  }

  .accordion-row {
    float: none;
    width: 100%;
    display: table-row;

    .accordion-title {
      font-weight: 600;
      color: #404040;
      font-size: 13px;
      float: left;
      width: 100%;
      background: #fafafa;
      border-top: 1px solid #ececec;
      border: none;

      .toggle-arrow {
        color: $blue;
        font-size: 15px;
        font-weight: 600;
        display: inline-block;
        margin: 0px 7px;
      }
    }
  }

  .accordion-head {
    background: #f0f1f2;
    font-weight: 600;
    color: #404040;
    font-size: 14px;
  }

  .accordion-discription-row {
    float: none;
    width: 100%;
    display: table;

    .accordian-section {
      min-height: 46px;

      .basic-checkbox-outer {
        position: relative;
        padding-left: 23px;

        input.basic-form-checkbox {
          position: absolute;
          left: 0px;
          top: 0px;
          margin-top: 0px;
        }
      }
    }

    .dash-icon {
      position: relative;
      padding-left: 50px;
      border-left: none !important;
      padding-top: 17px;

      &:after {
        content: "-";
        position: absolute;
        left: 38px;
        top: 14px;
        font-size: 18px;
      }
    }
  }
}

.question-img-outer {
  display: inline-block;
  text-align: center;
  margin: 15px 10px 10px 0px;
  vertical-align: top;
  position: relative;

  &.sel .question-img {
    border: 1px solid $blue;
  }

  .question-img {
    border: 1px solid #ddd;
    width: 96px;
    height: 96px;
    overflow: hidden;
    display: inline-block;

    img {
      max-width: 100%;
    }
  }

  .qust-name {
    width: 96px;
  }

  .check-quest {
    border: 1px solid $blue;
    @include border-radius(50%);
    display: inline-block;
    width: 30px;
    height: 30px;
    position: absolute;
    top: -14px;
    left: 50%;
    margin-left: -15px;
    background: url(/../images/new-check.png) no-repeat #fff;
    background-size: 15px !important;
    background-position: center !important;
    cursor: pointer;
    display: none;
  }

  &.sel .check-quest {
    display: block;
  }
}

.table {
  display: table;
  width: 100%;
  float: left;

  .table-row {
    display: table-row;

    .table-cell {
      display: table-cell;
    }
  }
}

.payment-Succeeded {
  background: #daedf7;
  display: inline-block;
  vertical-align: top;
  padding: 3px 10px;
  @include border-radius(30px);
  margin-top: 2px;
  min-width: 80px;
  text-align: center;
}

.payment-transit {
  background: #eeeeee;
  display: inline-block;
  vertical-align: top;
  padding: 3px 10px;
  @include border-radius(30px);
  margin-top: 2px;
  min-width: 80px;
  text-align: center;
}

.payment-failed {
  background: #fe9188;
  display: inline-block;
  vertical-align: top;
  padding: 3px 10px;
  @include border-radius(30px);
  margin-top: 2px;
  min-width: 80px;
  text-align: center;
}

.doc-section {
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-top: 1px solid #ececec;
  float: left;
  width: 100%;
  padding-top: 40px;
}

.sub-menus {
  width: 100%;
  display: block;
  list-style: none;
  border-bottom: 1px solid #dddddd;
  padding: 0px;

  li {
    display: inline-block;
    border-bottom: 2px solid #ffffff;

    a {
      display: block;
      font-size: 13px;
      color: #667680;
      padding: 13px 15px;

      &:hover {
        text-decoration: none;
      }
    }

    &.active,
    &:hover {
      border-bottom: 2px solid $blue;
    }
  }
}

.activity-menu {
  width: auto;
  margin: 0px 0px 20px;
  clear: both;
  padding: 0px 35px;
}

.sales-relation {
  float: right;
  font-size: 17px;
  font-weight: 600;
  margin-top: 8px;

  label {
    display: inline-block;
    font-weight: 400;
  }
}

.procedure-img {
  border: 1px solid #ececec;
  padding: 15px;
  float: left;
  width: 100%;
  margin-bottom: 20px;
  text-align: center;

  img {
    height: auto;
    width: auto;
  }
}

.consent-signature {
  height: 70px;
  margin-top: 24px;
}

ul.sub-menu {
  list-style: none;
  display: block;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  float: left;
  width: 100%;

  li {
    display: inline-block;

    a,
    button {
      border: none;
      background: none;
      color: #667680;
      padding: 15px 10px;
      display: block;
      font-size: 13px;
      font-weight: 400;
      outline: none;

      &:hover,
      &.active {
        border: none;
        color: $blue;
        margin-bottom: 0px;
      }
    }
  }
}

.new-search {
  width: 228px;
  float: left;
  margin-right: 7px;
}

.sm-container {
  padding-top: 25px;
  padding-bottom: 25px;
}

.service-hours-outer {
  max-width: 50%;
  float: left;
  width: 100%;
  margin-top: 20px !important;
}

.fromandtoTime {
  position: relative;

  .add-round-btn {
    top: 0px;
  }
}

.sm-btn {
  min-width: inherit;
  margin-left: 5px;
  padding: 7px 16px 4px;
  font-size: 12px;
  margin-top: -4px !important;
  height: 30px;
  line-height: 16px;
}

.new-calender {
  min-width: 200px;
  border: none;
  background: #f2f6f7;
  height: 32px;
  display: inline-block;
  padding: 0px;
  @include border-radius(3px);
  width: 213px;
  position: relative;
  padding-left: 33px;

  img {
    width: 21px;
    opacity: 0.3;
    margin: 4px 7px;
    position: absolute;
    left: 0px;
  }

  input {
    text-align: left;
    padding: 0px;
    border: 0px;
    height: 30px;
    font-size: 13px;
    width: 100%;
    vertical-align: middle;
    background: none;
    padding-top: 3px;
    text-align: center;
  }
}

.gray-row,
.gray-row:hover {
  background: #fafafa !important;
  color: #667680;
}

.gray-row td {
  font-weight: 600 !important;
}

.juvly-accordion {
  width: 100%;
  float: left;
  margin-bottom: 15px;
  transition: 0.4s;
  max-height: inherit !important;
}

.juvly-accordion-title {
  width: 100%;
  height: 50px;
  float: left;
  text-transform: uppercase;
  background: #eeeeee;
  font-size: 14px;
  font-weight: 600;
  @include border-radius(4px);
  padding: 15px;
  position: relative;
  cursor: pointer;
  color: #666666;
}

.juvly-accordion-title:hover {
  color: #666666;
}

.juvly-accordion-title:after {
  width: 23px;
  height: 23px;
  position: absolute;
  right: 10px;
  top: 13px;
  background: #a6a5a5;
  @include border-radius(50px);
  color: #ffffff;
  text-align: center;
  font-size: 22px;
  content: "+";
  font-weight: 600;
  line-height: 21px;
}

.juvly-accordion-content {
  border: 1px solid #ececec;
  float: left;
  width: 100%;
  border-top: none;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  border-bottom: none;
  display: none;
}

.juvly-accordion-option {
  float: left;
  width: 100%;
  padding: 15px 6px 15px 40px;
  position: relative;
  border-bottom: 1px solid #ececec;
  cursor: pointer;
}

.juvly-accordion-unselect {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 10px;
  top: 13px;
  border: 1px solid #dddddd;
  @include border-radius(50px);
}

.juvly-accordion-select {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 10px;
  top: 13px;
  border: 1px solid #dddddd;
  @include border-radius(50px);
  background: url(/../images/checked.png) no-repeat center;
}

.juvly-accordion-option.selected {
  background: #eff3ef;
}

.active.juvly-accordion-title:after {
  background: #ffffff;
  color: #a6a5a5;
  content: "-";
}

.active.juvly-accordion-content {
  overflow: visible;
  max-height: inherit;
}

.active.juvly-accordion-title {
  background: #2996cc;
  @include border-radius(4px 4px 0px 0px);
  color: #ffffff;
}

.juvly-accordion-title-blue .accordion-arrow {
  background: #ffffff;
  color: #2996cc;
}

.fill-report {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.report-fill-box {
  width: 70px;
  height: 30px;
  border: 1px solid #dddddd;
  display: inline-block;
  padding: 0px 7px;
}

.report-fill-selectbox {
  width: 250px;
  height: 30px;
  border: 1px solid #dddddd;
  display: inline-block;
  padding: 0px 26px 0px 7px;
  margin-bottom: 3px;
}

.report-question-outer {
  float: left;
  width: 100%;
  position: relative;
}

.report-question {
  font-size: 20px;
  font-weight: 500;
  float: left;
  width: 100%;
  color: #777777;
  margin-bottom: 8px;
}

.report-input {
  width: 100%;
  float: left;
  border: none;
  border-bottom: 1px solid #dddddd;
  height: 60px;
  outline: none;
  font-size: 22px;
  margin-bottom: 27px;
  color: #666666;
  font-weight: 300;
}

.report-input::placeholder {
  color: #cccccc;
  font-weight: 100;
}

.report-input::-ms-input-placeholder {
  color: #cccccc;
  font-weight: 100;
}

.report-btn,
.save-que,
.back-ok {
  background: #ffffff;
  @include border-radius(3px);
  @include box-shadow(0, 0px, 10px, 0, #cccccc);
  float: left;
  padding: 9px 17px 9px 25px;
  border: none;
  font-size: 17px;
  font-weight: 600;
  color: #666666;
  outline: none;
  position: relative;
}

.report-btn:hover,
.save-que:hover,
.back-ok:hover {
  background: $blue;
  color: #ffffff;
}

.report-btn:after,
.save-que:after,
.back-ok:after {
  position: absolute;
  right: -100px;
  top: 10px;
  font-size: 14px;
  color: #666666;
  text-transform: inherit !important;
  font-weight: 400;
}

.report-btn svg,
.save-que svg,
.back-ok svg {
  font-size: 16px;
  margin-left: 4px;
}

.report-name {
  margin-bottom: 80px;
}

.empty-place {
  border-bottom: 1px solid #aaaaaa;
  display: inline-block;
  min-width: 200px;
  min-height: 30px;
  vertical-align: top;
  color: $blue;
  text-align: center;
  margin-bottom: 10px;
}

.empty-place.active {
  color: $blue;
}

input.report-input:focus {
  border-bottom: 1px solid $blue;
}

select.report-select:focus {
  border-bottom: 1px solid $blue;
  outline: none;
}

.setting-custom-switch #enabled-text,
.setting-custom-switch #disabled-text {
  padding-top: 2px;
  padding-right: 10px;
  display: inline-block;
}

.setting-custom-switch #disabled-text {
  display: none;
}

.search-text {
  display: inline-block;
  float: left;
  padding: 7px 0px 6px 10px;
  font-weight: normal;
  font-size: 13px;
  margin: 0px;
}

.right-sign-btn {
  margin: 0px;
  display: flex;
  width: 41%;
  float: right;
}

.hide-button-show-drowpdown-div {
  @extend .right-sign-btn; // Inherit properties from .right-sign-btn
  justify-content: end; // Align content to the end along the main axis
}

.sel-all-visible {
  margin: 8px 0px !important;
}

.table-checkbox input {
  margin-right: 6px;
  cursor: pointer;
}

.wide-popup {
  position: fixed;
  z-index: 12;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  background: #ffffff;
  overflow-y: auto;
}

.modal-blue-header {
  background: #c2e0f2;
  @include border-radius(0px);
  color: $blue;
  display: block;
  font-size: 20px;
  height: 52px;
  left: 0;
  margin-bottom: 18px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 100;
  padding: 15px 14px;
  top: 0px;
}

.popup-cross {
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  line-height: 21px;
  color: #404040;
  left: 15px;
  right: inherit;
  position: absolute;
  font-size: 27px;
  height: 20px;
  width: 20px;
  margin-top: 0px;
  opacity: 0.4;
  font-weight: bold;
}

.popup-cross:hover {
  text-decoration: none;
  opacity: 1;
  cursor: pointer;
}

.popup-blue-name {
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  line-height: 21px;
  color: #404040;
  vertical-align: top;
}

.modal-blue-body {
  padding: 80px 20px 20px 20px;
  background: #fff;
  box-shadow: 0px 1px 1px #ddd;
  border-radius: 5px;
}

.popup-new-btns {
  position: absolute;
  right: 20px;
  top: 11px;
}

.slide-arrows {
  display: inline-block;
  vertical-align: top;
  padding: 0px 5px;
  font-size: 17px;
  cursor: pointer;
  color: $blue;
  width: 33px;
  margin-top: -3px;

  svg {
    width: 20px !important;
  }
}

.wide-popup-wrapper.time-line {
  display: table !important;
  width: 800px !important;
  margin: 100px auto 0px;
  float: none !important;
}

.procedure-popup {
  border: 1px solid #ececec;
  padding: 15px 0px;
  margin: 20px 0px !important;
}

.popup-pro-name-outer {
  min-height: 80px;
}

.export .dropdown-toggle {
  font-size: 14px;
  font-family: "Lato", sans-serif;
  background: none !important;
  color: #667680 !important;
  float: right;
  border: none;
  padding: 6px 9px 6px 9px;
}

.export .dropdown {
  background: #f2f6f7 !important;
  @include border-radius(3px);
}

.export button {
  padding-right: 20px !important;
}

.export .dropdown.open button {
  background: $blue !important;
  color: #ffffff !important;
}

.export ul,
.report-export ul {
  left: inherit !important;
  top: 100% !important;
  left: inherit;
  right: 0px;
  padding: 0px;
  font-size: 14px;
  border: none;
  transform: inherit !important;
}

.export ul a:hover {
  color: #ffffff;
}

.header-select {
  float: left;
  @include border-radius(3px);
  position: relative;

  select {
    float: left;
    padding-right: 15px;
    @include border-radius(3px);
    width: 150px;
    background: #f2f6f7;
    border: none;
    padding: 6px 20px 6px 9px;
    margin: 0px 7px;
    font-size: 14px;
  }

  svg {
    position: absolute;
    right: 12px;
    top: 10px;
    font-size: 13px;
  }
}

.export {
  svg {
    position: absolute;
    right: 6px;
    top: 10px;
    font-size: 13px;
  }
}

.modalOverlay {
  background: rgba(96, 117, 133, 0.77);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000;
}

.small-popup-outer {
  width: 580px;
  height: auto;
  margin: auto;
  background: #ffffff;
  @include border-radius(5px);
  margin-top: 90px;
  overflow: hidden;
  position: relative;

  .small-popup-header {
    width: 100%;
    min-height: 45px;
    float: left;
    background: #c2e0f2;
    @include border-radius(5px 5px 0px 0px);
    padding: 0px 15px 0px 30px;
    position: relative;

    .go-back {
      float: left;
      color: #047ec3;
      font-size: 20px;
      font-weight: normal;
      margin-top: 8px;
      margin-right: 7px;
      position: absolute;
      left: 12px;
      background: none;
      border: none;
    }

    .popup-name {
      font-size: 12px;
      text-transform: uppercase;
      line-height: 21px;
      color: #404040;
      vertical-align: top;
      float: left;
      padding: 13px 0px 0px;
    }

    .small-cross {
      color: #2c3e50;
      font-size: 23px;
      height: 20px;
      margin-top: 7px;
      float: right;
      left: inherit;
      right: 15px;
      position: absolute;
      opacity: 0.4;
      font-weight: 800;

      &:hover {
        opacity: 1;
      }
    }
  }

  .small-popup-content {
    float: left;
    width: 100%;
    max-height: 383px;
    overflow-y: auto;
    overflow-x: auto;
  }
}

.filter-title {
  float: left;
  width: 100%;
  padding: 10px 15px;

  span {
    width: auto;
    margin: 0px;
    padding-top: 6px;
  }
}

.filter-table {
  svg {
    position: absolute;
    left: 12px;
    color: #667680;
    margin-top: 4px;
    opacity: 0.5;
  }

  a {
    padding: 6px 5px;
    visibility: hidden;
  }

  tr:hover a {
    visibility: visible;
  }
}

.small-popup-content .juvly-container {
  padding: 30px;
}

@-webkit-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

@-moz-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

@keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-moz-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }

  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }

  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }

  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

.dropzone,
.dropzone * {
  box-sizing: border-box;
}

.dropzone {
  min-height: 150px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  background: white;
  padding: 20px 20px;
}

.dropzone.dz-clickable {
  cursor: pointer;
}

.dropzone.dz-clickable * {
  cursor: default;
}

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
  cursor: pointer;
}

.dropzone.dz-started .dz-message {
  display: none;
}

.dropzone.dz-drag-hover {
  border-style: solid;
}

.dropzone.dz-drag-hover .dz-message {
  opacity: 0.5;
}

.dropzone .dz-message {
  text-align: center;
  margin: 2em 0;
}

.dropzone .dz-preview {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 16px;
  min-height: 100px;
}

.dropzone .dz-preview:hover {
  z-index: 1000;
}

.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}

.dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 20px;
  background: #999;
  background: linear-gradient(to bottom, #eee, #ddd);
}

.dropzone .dz-preview.dz-file-preview .dz-details {
  opacity: 1;
}

.dropzone .dz-preview.dz-image-preview {
  background: white;
}

.dropzone .dz-preview.dz-image-preview .dz-details {
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.dropzone .dz-preview .dz-remove {
  font-size: 14px;
  text-align: center;
  display: block;
  cursor: pointer;
  border: none;
}

.dropzone .dz-preview .dz-remove:hover {
  text-decoration: underline;
}

.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}

.dropzone .dz-preview .dz-details {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 13px;
  min-width: 100%;
  max-width: 100%;
  padding: 2em 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  line-height: 150%;
}

.dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 1em;
  font-size: 16px;
}

.dropzone .dz-preview .dz-details .dz-filename {
  white-space: nowrap;
}

.dropzone .dz-preview .dz-details .dz-filename:hover span {
  border: 1px solid rgba(200, 200, 200, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: 1px solid transparent;
}

.dropzone .dz-preview .dz-details .dz-filename span,
.dropzone .dz-preview .dz-details .dz-size span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.4em;
  border-radius: 3px;
}

.dropzone .dz-preview:hover .dz-image img {
  -webkit-transform: scale(1.05, 1.05);
  -moz-transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  -o-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-filter: blur(8px);
  filter: blur(8px);
}

.dropzone .dz-preview .dz-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
}

.dropzone .dz-preview .dz-image img {
  display: block;
}

.dropzone .dz-preview.dz-success .dz-success-mark {
  -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview.dz-error .dz-error-mark {
  opacity: 1;
  -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview .dz-success-mark,
.dropzone .dz-preview .dz-error-mark {
  pointer-events: none;
  opacity: 0;
  z-index: 500;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin-left: -27px;
  margin-top: -27px;
}

.dropzone .dz-preview .dz-success-mark svg,
.dropzone .dz-preview .dz-error-mark svg {
  display: block;
  width: 54px;
  height: 54px;
}

.dropzone .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.dropzone .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-in;
  -moz-transition: opacity 0.4s ease-in;
  -ms-transition: opacity 0.4s ease-in;
  -o-transition: opacity 0.4s ease-in;
  transition: opacity 0.4s ease-in;
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse 6s ease infinite;
  -moz-animation: pulse 6s ease infinite;
  -ms-animation: pulse 6s ease infinite;
  -o-animation: pulse 6s ease infinite;
  animation: pulse 6s ease infinite;
}

.dropzone .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  height: 16px;
  left: 50%;
  top: 50%;
  margin-top: -8px;
  width: 80px;
  margin-left: -40px;
  background: rgba(255, 255, 255, 0.9);
  -webkit-transform: scale(1);
  border-radius: 8px;
  overflow: hidden;
}

.dropzone .dz-preview .dz-progress .dz-upload {
  background: #333;
  background: linear-gradient(to bottom, #666, #444);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  -webkit-transition: width 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out;
  -ms-transition: width 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out;
  transition: width 300ms ease-in-out;
}

.dropzone .dz-preview.dz-error .dz-error-message {
  display: block;
}

.dropzone .dz-preview.dz-error:hover .dz-error-message {
  opacity: 1;
  pointer-events: auto;
}

.dropzone .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  position: absolute;
  display: block;
  display: none;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  border-radius: 8px;
  font-size: 13px;
  top: 130px;
  left: -10px;
  width: 140px;
  background: #be2626;
  background: linear-gradient(to bottom, #be2626, #a92222);
  padding: 0.5em 1.2em;
  color: white;
}

.dropzone .dz-preview .dz-error-message:after {
  content: "";
  position: absolute;
  top: -6px;
  left: 64px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #be2626;
}

.field-error {
  border-bottom: 1px solid red;
}

.ar-url {
  width: 61px;
  display: inline-block;
  float: none;
}

.analytics-measurement-field {
  max-width: 420px;

  @media screen and (max-width: 990px) {
    max-width: 660px;
  }
}

.field_error {
  border-bottom: 1px solid #e52817 !important;
}

.portal-numbers {
  font-size: 56px;
  font-weight: bold;
  color: #404040;
  display: block;
  font-family: "Lato", sans-serif;
  margin-bottom: 10px;
  margin-top: 50px;
}

textarea.setting-input-box {
  height: 100% !important;
}

.field-error {
  border-bottom: 1px solid #e52817 !important;
}

.switch-accordian-outer .closed {
  color: #777777;
}

.field_error {
  border-bottom: 1px solid #e74c3c !important;
}

.hidePassword {
  position: absolute;
  right: 0px;
  top: 0px;
  background: #fff;
  width: 21px;
  text-align: right;
}

.gene-pascod {
  margin-top: 5px;
  padding: 7px 9px 4px;
  font-size: 12px;
}

.passcode {
  width: 34%;
  display: inline-block;
}

.search-text {
  display: inline-block;
  float: left;
  padding: 7px 0px 6px 10px;
  font-weight: normal;
  font-size: 13px;
  margin: 0px;
}

.loading-please-wait {
  width: 100%;
  text-align: center;
  margin-top: -35px;
  float: left;
}

.new-calender img {
  width: 19px;
  opacity: 0.3;
  margin: 6px 7px;
  position: absolute;
  left: 0px;
}

.no-dz-image .dz-image-preview {
  display: none !important;
}

.main-profile-picture {
  left: 15px;
  top: 0px;
  height: auto;
  width: 155px;
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: top;
  position: absolute;
  z-index: 1;
}

.dropzone-holder {
  border: 1px solid #dddddd;
  min-height: inherit;
  background: inherit;
  padding: inherit;
  width: inherit;
  position: inherit;
  background: inherit;
  background-size: inherit;
  cursor: inherit;
  box-sizing: inherit;
  bottom: inherit;
  z-index: 9;
  color: $blue;
  font-size: 16px !important;
  line-height: 31px !important;
  @include border-radius(5px);
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 155px;
  height: auto;

  &:hover .camera-in-preview {
    display: none;
  }

  .dropzone {
    padding: 0px;
    border: none;
    min-height: inherit;

    & > div {
      display: none !important;
    }

    & > div:last-child {
      display: block !important;
    }
  }

  .no-dz-image {
    display: none;
  }

  .dz-error {
    display: none !important;
  }

  .dz-preview {
    position: initial;
    display: table;
    width: 100%;
    margin: 0px;
  }

  .dz-message {
    display: none;
  }

  .dz-preview {
    .dz-image {
      width: 155px;
      height: 155px;
      display: -webkit-flex;
      -webkit-align-items: center;
      display: flex;
      align-items: center;
      background: #eeeeee;
      @include border-radius(5px);
      vertical-align: middle;
      overflow: hidden;
      text-align: center;

      img {
        display: block;
        vertical-align: middle;
        height: auto;
        width: 100%;
      }
    }

    .dz-details {
      z-index: 20;
      position: absolute;
      top: 0;
      left: 0;
      @include opacity(0);
      font-size: 13px;
      min-width: 100%;
      max-width: 100%;
      padding: 1em 1em;
      text-align: center;
      color: rgba(0, 0, 0, 0.9);
      line-height: 150%;
    }

    .dz-remove {
      line-height: 30px;
      background: $white-color;
      position: absolute !important;
      right: 10px;
      top: 10px;
      z-index: 50;
      @include border-radius(50px);
      width: 30px;
      height: 30px;
      font-weight: bold;
      text-decoration: none;
      text-indent: 100px;
      overflow: hidden;
      font-size: 27px;
      position: relative;
      border: none;
    }

    .dz-remove:after {
      content: "";
      font-size: 27px !important;
      position: absolute;
      right: 9px;
      top: 9px;
      font-size: 27px;
      width: 11px;
      height: 11px;
      background: url(/../images/close.png) no-repeat center;
    }
  }

  .camra-icon {
    right: 10px;
    bottom: 10px;
    position: absolute;
    z-index: 10;
    background: #fff;
    height: 30px;
    width: 30px;
    @include border-radius(50px);
    @include box-shadow(0, 2px, 1px, 0, #ddd);
    text-align: center;
    line-height: 29px;

    svg {
      position: absolute;
      right: 7px;
      top: 7px;
      z-index: 2;
      color: #047ec3;
    }
  }

  .dz-default,
  .dz-message {
    display: none !important;
  }
}

.image_questionnaire {
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
  height: 20px;
  width: 100px;
}

.editor_textarea.field_error {
  border: none !important;
}

.editor_textarea.field_error .mce-statusbar {
  border-bottom: none !important;
}

.editor_textarea.field_error .mce-tinymce {
  border-bottom: 1px solid #e74c3c !important;
  box-shadow: inherit !important;
}

.mce-tinymce {
  box-shadow: inherit !important;
}

.react-tagsinput {
  border: none !important;
  padding: 0px !important;
  background: none !important;
}

.react-tagsinput-tag {
  background-color: #f5f5f5 !important;
  border: 1px solid #ddd !important;
  color: #404040 !important;
}

.simpleInputOuter,
.simpleField {
  .react-tagsinput-tag {
    padding: 2px 5px !important;
    margin-bottom: 2px !important;
  }

  .react-tagsinput-input {
    margin-bottom: 0px;
  }
}

.react-tagsinput-remove {
  color: #999999;
}

.traceProduct {
  font-size: 14px;
  margin-bottom: 10px;
}

.small-popup-title {
  padding: 27px 50px 35px;
  float: left;
  width: 100%;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.proDetailLoader {
  position: fixed !important;
}

.multi-sel-btn {
  height: 32px;
  background: #f2f6f7;
  float: left;
  margin-left: 7px;
  padding: 5px 10px;
  color: #667680;
  font-size: 14px;
  @include border-radius(3px);

  &.active {
    background: $blue;
    color: #ffffff;
    cursor: pointer;
  }
}

.new-dropdown-menu {
  padding: 0px;
  width: auto !important;
  margin-top: 13px;
  border: 1px solid #ececec;
  left: 15px;
  right: 15px;
  border-bottom: none;
  z-index: 9;
  max-height: 340px;
  overflow-y: auto;
  position: absolute;
  background: #ffffff;
  font-size: 14px;
  @include border-radius(3px);
  @include box-shadow(0, 2px, 1px, 0, #ddd);

  #select_btn_li {
    border-bottom: 1px solid #ececec;
    text-align: right;
    display: block;
    vertical-align: top;
    padding: 6px;
    background: #fafafa;
    width: 100% !important;
  }

  #search_select_btn_li {
    border-bottom: 1px solid #ececec;
    padding: 6px;
    background: #fafafa;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100% !important;

    .simpleInput {
      max-width: 300px;
    }
  }

  li {
    display: inline-block;
    vertical-align: top;
    float: left;
    width: 25%;

    &:hover {
      background: #e9f4f9;
    }

    label {
      font-size: 14px;
      font-family: "Lato", sans-serif;
      color: #667680 !important;
      font-weight: normal;
      cursor: pointer;
      width: 100%;
      display: block;
      vertical-align: top;
      line-height: 16px;
      margin: 0px;
      padding: 10px;
      border-bottom: 1px solid #ececec;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      input {
        margin-top: 0px;
        vertical-align: top;
        line-height: 19px;
        margin-right: 8px;
        display: inline-block;
        margin-left: 0px;
        cursor: pointer;
      }
    }
  }
}

.page-export button {
  min-width: inherit;
  padding-left: 10px;
  padding-right: 22px;
  margin-left: 7px !important;
  height: 32px;
}

.help-icon-form {
  width: 17px;
  height: 17px;
  text-align: center;
  @include border-radius(50px);
  border: 1px solid $blue;
  color: $blue;
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  vertical-align: top;
  margin-top: -2px;
  margin-left: 5px;
  padding: 0px !important;
}

.pagination {
  .page-link {
    color: #337ab7 !important;
    border: 1px solid #ddd !important;
    background: none !important;

    &:hover {
      color: #337ab7 !important;
      background: #eeeeee !important;
    }
  }

  .page-item.active a {
    cursor: default;
    color: #ffffff !important;
    border: 1px solid $blue !important;
    background: $blue !important;
  }

  .page-item.disabled a {
    cursor: not-allowed;
    color: #999 !important;
    background: none !important;
  }
}

.react-autosuggest__suggestions-list {
  position: absolute;
  z-index: 100;
  background: #fff;
  left: 0px;
  right: 0px;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ececec;
  padding: 10px 0px;
  z-index: 1;

  .react-autosuggest__suggestion {
    color: #667680;
    padding: 2px 10px;

    &:hover {
      color: $blue;
      background: #f7fbfd;
      cursor: pointer;
    }
  }

  .react-autosuggest__suggestion--highlighted {
    color: $blue;
    background: #f7fbfd;
    cursor: pointer;
  }
}

.tag-auto-select {
  margin-top: 0px;
  min-height: 29px;

  .field_error {
    margin-top: -5px;
    width: 103% !important;
    margin-left: -3% !important;
    @include border-radius(3px);
  }

  > div > div:nth-child(2) {
    box-shadow: inherit;
    border: none !important;
    border-bottom: 1px solid #bfc9ce !important;
    border-radius: 0px;
    -webkit-border-radius: 0px;

    > div {
      padding: 0px;
    }

    > div:last-child > div:first-child {
      display: none;
    }
  }

  > div > div:first-child {
    border: none !important;
    border-bottom: 1px solid #bfc9ce !important;
    @include border-radius(0px);
    border-radius: 0px;
    -webkit-border-radius: 0px;
    outline: none;

    > div {
      padding: 0px !important;
    }

    svg {
      color: $blue;
    }

    > div:last-child > div:first-child {
      display: none;
    }
  }

  > div > div:last-child {
    margin-top: -1px;
  }
}

.simpleField .tag-auto-select > div > div:first-child,
.simpleField .tag-auto-select > div > div:nth-child(2) {
  border: none !important;
  background: none !important;
}

select.setting-select-box:disabled,
input.setting-input-box:disabled,
textarea.setting-textarea-box:disabled,
input:disabled + .setting-slider {
  opacity: 0.5;
  cursor: not-allowed;
}

.rdrPprevButton i {
  width: auto !important;
}

.intl-tel-input {
  display: block;
  width: 100%;
}

.iti-arrow {
  right: 0px !important;
}

.flag-container:hover .selected-flag {
  background: none !important;
}

.selected-flag {
  margin-top: -6px;
  padding-left: 0px !important;
  border-right: 10px solid #ffffff;
  height: 32px !important;
  outline: none;
}

.klarna-tel-input {
  .selected-flag {
    margin-top: 0;
    padding-left: 8px !important;
    border: none;
    height: 90% !important;
  }
}

.iti-arrow {
  background: url(/../images/new-down-arrow.png) no-repeat right 9px;
  border: none !important;
  width: 10px !important;
  height: 16px !important;
  margin-top: -10px !important;
}

.url-save {
  margin: 13px 0px 10px 0px;
}

#cc_auth_text {
  height: 26px !important;
}

.mce-notification,
.mce-branding {
  display: none !important;
}

.mce-tinymce iframe {
  height: 200px !important;
}

.portal-stats-outer {
  text-align: center;
  margin-bottom: 80px;
}

.patient-portal-label {
  color: #667680;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  margin-bottom: 15px;
}

.dash-access {
  margin-right: 10px;
  float: left;
}

#manage-privileges {
  margin-right: 0px;
  margin-top: -6px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px !important;
}

.react-datepicker {
  font-size: 12px !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 24px !important;
  line-height: 24px !important;
}

.css-10nd86i {
  float: left;
  width: 100%;
}

.css-vj8t7z,
.css-2o5izw {
  min-height: 26px !important;
}

.css-d8oujb {
  display: none;
}

.css-1ep9fjw,
.css-1uq0kb5 {
  padding: 0px !important;
}

.payment-currancy {
  font-weight: normal;
  display: inline-block;
  vertical-align: top;
  color: #999999;
  width: 36px;
  text-align: left;
  margin-top: 4px;
}

.payment-Succeeded {
  background: #daedf7;
  display: inline-block;
  vertical-align: top;
  padding: 3px 10px;
  @include border-radius(30px);
  margin-top: 2px;
  min-width: 80px;
  text-align: center;
}

.payment-transit {
  background: #eeeeee;
  display: inline-block;
  vertical-align: top;
  padding: 3px 10px;
  @include border-radius(30px);
  margin-top: 2px;
  min-width: 80px;
  text-align: center;
  font-size: 14px;
}

.payment-amount {
  font-weight: normal;
  display: inline-block;
  vertical-align: top;
  color: #404040;
  margin: 6px 0px;
}

.prescription-content > div:first-child {
  border: none;
  padding-top: 0px;
}

.prescription-content .add-round-btn {
  right: -15px;
}

.usd-width {
  min-width: 130px;
}

.stripe-unverified {
  color: red !important;
}

.stripe-verified {
  color: green !important;
}

.pos-stripe-outer {
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  padding-left: 30px;
}

.clinic-account {
  float: left;
  width: 100%;
  border-bottom: 1px dashed #ececec;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

#per-clinic-settings > div.clinic-account:last-child {
  border: none;
  margin-bottom: 0px;
}

.bank-logo {
  margin-right: 7px;
}

.payment-response {
  background: $yellow;
  display: inline-block;
  vertical-align: top;
  padding: 3px 10px;
  @include border-radius(30px);
  margin-top: 2px;
  min-width: 80px;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
}

.response,
.yellow {
  color: $yellow !important;
}

.failed {
  color: #fe9188 !important;
}

.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  background: #047ec3 !important;
  color: #ffffff;
}

.rdrInfiniteMonths {
  overflow-y: auto;
  overflow-x: hidden;
}

.clientProfileLoader {
  z-index: 9999 !important;
  position: fixed !important;
}

.gray-export button {
  padding-right: 10px !important;
}

.invoice-textarea {
  min-height: 452px !important;
  display: table;
}

.invoice-discraimer {
  padding-right: 50px;
}

.border-radius-top {
  @include border-radius(5px 5px 0px 0px);
}

.gray-export button {
  padding-right: 20px !important;
}

.btn.btn-default.dropdown-toggle svg {
  margin-right: 0px;
  position: absolute;
}

#get_filters svg {
  position: inherit !important;
}

.customer-note {
  border: 1px solid #ececec;
  border-radius: 5px;
  margin-top: 15px;
  padding: 10px 20px;
  float: left;
  width: 100%;
  margin-bottom: 15px;
}

.check-note {
  padding-left: 22px;
  position: relative;
  float: left;
  width: 100%;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  color: #667680;
  margin-bottom: 10px;
}

.check-note .note-check {
  left: 0;
  position: absolute;
  top: -3px;
}

.check-note .check-note-text {
  font-size: 13px;
  word-break: break-all;
}

ul.search-dropdown {
  position: absolute;
  right: 0px;
  left: 0px;
  top: 45px;
  @include border-radius(3px);
  z-index: 9999;
  max-height: 306px;
  overflow-y: auto;
  width: 100% !important;
  background: #ffffff;
  border: 1px solid #dddddd;

  li {
    display: block;
    list-style: none;
    border-bottom: 1px solid #dddddd;

    a {
      display: block;
      padding: 8px;
      font-size: 13px;
      color: #444444;
      text-decoration: none !important;

      &:hover {
        background: #f5f5f5;
        color: $blue;
      }
    }
  }
}

.positionFixed {
  position: fixed !important;
  z-index: 11000;
}

.file-container {
  width: 122px;
  height: 137px;
  border: 1px solid #dddddd;
  float: left;
  @include border-radius(5px);
  position: relative;
  overflow: hidden;
  background: #ffffff;

  img {
    width: 100%;
    float: left;
  }

  .file-info {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: none;
    position: absolute;
    top: 50px;
    z-index: 1;
    background: rgba(255, 255, 255, 0.5);
    color: #000000;
    text-align: center;
    width: 100%;
    left: 0px;
  }

  .file-size {
    top: 15px;
  }

  .upload {
    background: #ffffff;
    padding: 8px 0px;
    border-top: 1px solid #dddddd;
    position: absolute;
    bottom: 0px;
    width: 100%;
    font-size: 13px;
    color: #404040;
    text-align: center;
    @include border-radius(0px 0px 3px 3px);
    cursor: pointer;
    overflow: hidden;

    input {
      width: 100%;
      height: 33px;
      cursor: pointer;
    }
  }

  &:hover .file-info {
    display: block;
  }
}

.add-edit-procedure .react-datepicker-popper {
  width: 275px;
}

.react-datepicker-popper {
  .react-datepicker__navigation--previous {
    &::after {
      content: "";
      display: inline-block;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 5px solid $blue;
    }
  }

  .react-datepicker__navigation--next {
    &::after {
      content: "";
      display: inline-block;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid $blue;
    }
  }

  .react-datepicker__current-month {
    font-size: 12px;
    margin-bottom: 5px;
  }

  select {
    padding: 1px 5px;
    display: inline-block;
    background: url(/../images/new-down-arrow.png) no-repeat 65px 7px #ffffff;
    width: 80px;
  }

  .react-datepicker__day:hover {
    background-color: $blue;
    color: #ffffff;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 30px !important;
  }
}

.rdrDayInPreview {
  display: none !important;
}

.giftcard-suggestion {
  position: absolute;
  left: 0px;
  width: 100%;
  top: 32px;
  border: 1px solid #ececec;
  z-index: 2;

  ul {
    background: #fff;
    list-style: none;
    margin: 0px;
    box-shadow: 0px 1px 4px 0px #ccc;

    .suggestion-li {
      display: block;
      border-bottom: 1px solid #ececec;

      .suggestion-a {
        display: block;
        padding: 5px;
        color: #444444;

        &:hover {
          background: #f5f5f5;
        }

        span {
          color: $blue;
        }
      }
    }
  }
}

.file-upload-img {
  img {
    float: none;
    width: 89px;
    float: none;
    margin: 5px auto;
    display: table;
  }
}

.new-search.filter-by-search {
  width: 267px;

  input {
    padding-right: 100px;
  }

  .header-select {
    position: absolute;
    right: 0px;
    top: 0px;

    select {
      width: 97px !important;
      min-width: 108px !important;
      margin: 0px;
      background: $blue;
      color: $white-color;
      @include border-radius(0px 3px 3px 0px);
    }

    svg {
      color: $white-color;
      right: 7px;
    }
  }
}

.modal-footer {
  .btn-success {
    background-color: $blue;
    border-color: $blue;

    &:hover {
      background-color: $blue-hover;
      border-color: $blue-hover;
    }
  }
}

.invoice-footer .dropdown-menu li a {
  padding: 3px 7px;
}

.wide-popup-wrapper.no-table {
  display: inherit !important;
}

.xs-popup {
  width: 350px !important;
}

.something-wrong {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -241px;
  margin-top: -165px;
  max-width: 100%;
  width: 482px;
  height: auto;
  text-align: center;
  padding-top: 0px !important;

  a.click-logout {
    @include box-shadow(0, 1px, 2px, 0, rgba(0, 0, 0, 0.15));
    border: none;
    padding: 7px 25px;
    height: 40px;
    font-size: 18px;
    color: $white-color;
    background-color: $blue;
    @include border-radius(3px);
    display: inline-block;
    vertical-align: top;
    min-width: 103px;
    text-align: center;
    text-decoration: none !important;
    font-family: $lato;
    line-height: 24px;
    margin-top: 40px;
  }
}

#edit_time_box .ar-url {
  width: 81px !important;
}

.property-select-outer .custom-select * {
  font-size: inherit !important;
}

.StripeElement--invalid {
  border-bottom: 1px solid #e74c3c !important;
}

.fixed-header-table {
  table {
    position: relative;

    thead {
      position: sticky;

      tr {
        width: 100% !important;
        overflow: hidden;
      }
    }

    tbody {
      height: auto !important;
      float: left;
      width: 100%;

      tr {
        display: -webkit-flex;
        -webkit-flex-wrap: wrap;
        display: flex;
        flex-wrap: wrap;

        td {
          min-height: inherit !important;
          word-break: break-all;

          .easy-link {
            padding: 0px 10px;
            width: 48px;
          }

          .easy-link-remove {
            width: auto;

            &:first-child {
              margin-left: 48px;
            }
          }

          .easy-link-standard {
            width: auto;
          }
        }
      }
    }
  }
}

.field_error_span {
  color: #c73426;
  font-size: 13px;
  float: left;
}

.dot-phrase-list {
  font-size: 13px;
  right: 15px !important;
  top: 104px !important;

  li {
    span {
      font-weight: bold;
      color: #404040;
    }

    p {
      color: #404040;
      margin-bottom: 0px;
    }
  }
}

.phase-discription {
  overflow: auto !important;
}

.upload-img-cross {
  line-height: 30px;
  background: #ffffff;
  position: absolute !important;
  right: 5px;
  top: 5px;
  z-index: 50;
  @include border-radius(50px);
  width: 30px;
  height: 30px;
  font-weight: bold;
  text-decoration: none;
  text-indent: 100px;
  overflow: hidden;
  font-size: 27px;
  position: relative;
  border: none;
  cursor: pointer;

  &:after {
    content: "";
    font-size: 27px !important;
    position: absolute;
    right: 9px;
    top: 9px;
    font-size: 27px;
    width: 11px;
    height: 11px;
    background: url(/../images/close.png) no-repeat center;
    cursor: pointer;
  }
}

.flex-wrap {
  .table-updated-tr {
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    display: flex;
    flex-wrap: wrap;
  }
}

.blackOverlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 100;
  overflow-y: auto;
  overflow-x: hidden;
}

.Toastify__toast-container {
  z-index: 999999 !important;
}

.Toastify__toast-container--bottom-right {
  right: 6em !important;
}

.modal .modal-footer .btn {
  background-color: #dddddd;

  &.btn-success {
    background-color: $blue;
  }
}

.trac-info {
  margin-bottom: 10px;
}

.invoice-preview-outer {
  overflow-x: auto;
  float: left;
  width: 100%;
}

.repeat-price {
  margin-top: -5px;
  padding-left: 17px;
  position: relative;

  .input {
    position: absolute;
    left: 0px;
    top: 10px;
  }
}

.simpleInput.add-btn {
  padding-right: 70px !important;
  position: relative;

  input {
    height: 30px;
    width: 100%;
    float: left;
    margin-top: 2px;
    background: none;
  }

  .blue-btn {
    position: absolute;
    right: 2px;
    top: 2px;
  }
}

.simpleLabel {
}

.membershipLabel {
  color: #444444;
  font-size: 15px;
}

.simpleField {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  margin-top: 5px;

  > div {
    clear: both;
  }

  .choose-file-outer {
    padding: 7px !important;
    z-index: 0 !important;

    .remove-file-name {
      top: 5px;
      right: 5px;
    }
  }
}

.simpleLabel {
  float: left;
  font-size: 13px;
  color: $blue;
  background: #ffffff;
  position: relative;
  padding: 0px 4px;
  top: 1px;
  min-height: 18px;
  z-index: 1;
  margin: 0px 11px -7px;

  .fieldRequired {
    color: $blue;
  }
}

.simpleInput {
  width: 100%;
  float: left;
  color: #404040;
  border: 1px solid #cad2d6;
  @include border-radius(3px);
  height: 36px;
  padding: 0px 15px;
  font-size: 14px;
  background: #ffffff;
}

.simpleSelect {
  width: 100%;
  float: left;
  color: #404040;
  border: 1px solid #cad2d6;
  @include border-radius(3px);
  background-image: url(/../images/select-arrow2.png);
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 10px auto;
  background-color: #ffffff;
  height: 36px;
  padding: 0px 20px 0px 15px;
  font-size: 14px;
}

.simpleTextarea {
  width: 100%;
  float: left;
  color: #404040;
  border: 1px solid #cad2d6;
  @include border-radius(3px);
  height: 150px;
  padding: 10px 15px;
  font-size: 14px;
}

.field-icon {
  position: relative;

  .relative a {
    top: 7px !important;
  }

  a {
    position: absolute;
    right: 7px !important;
    top: 19px !important;
  }
}

.simple-cal-icon {
  background-image: url(/../images/appointment-hover.png);
  width: 19px;
  height: 19px;
  z-index: 1;
}

.field-icon .react-datepicker-popper {
  margin-top: 44px !important;
}

.white-popup {
  width: 550px;
  height: auto;
  margin: auto;
  // display: table;
  background: #ffffff;
  @include border-radius(5px);

  .TP-discount-outer {
    max-width: inherit !important;
  }

  .white-popup-wrapper {
    padding: 25px;
    float: left;
    width: 100%;
  }
}

.group-error {
  border: 1px solid #e74c3c !important;
}

.activateEmail {
  margin-top: 5px;

  .factor_status {
    margin: -1px 5px 0px 0px !important;
    padding: 0px;
  }
}

.blue-required {
  font-size: 13px;
  color: $blue;
}

.filled-border {
  @include border-radius(3px);
  border: 1px solid #cad2d6;

  &.input-fill-box {
    margin: 0px 5px;
  }

  &.field-error {
    border: 1px solid #e74c3c !important;
  }
}

.simpleInput,
.simpleTextarea,
.simpleSelect {
  &.field-error {
    border: 1px solid #e74c3c !important;
  }
}

.tag-auto-select .css-1pcexqc-container .css-kj6f9i-menu {
  z-index: 50 !important;
}

.Toastify__toast-container--bottom-right {
  z-index: 20000 !important;
}

.intl-tel-input {
  .flag-container {
    .selected-flag {
      width: 46px !important;
      background: none !important;

      .selected-dial-code {
        display: none !important;
      }
    }
  }

  input {
    padding-left: 48px !important;
  }
}

.eight-dots {
  width: 6px;
  height: 19px;
  display: inline-block;
  background: url(/../images/eight-dots.png) no-repeat 50%;
  position: absolute;
  left: 15px;
  top: 50%;
  margin-top: -8px;
}

.business-insight {
  ul {
    li {
      display: block;
      margin-bottom: 10px;

      a {
        display: block;
        padding: 8px 15px 11px;
        color: #667680;
        font-size: 15px;
        text-align: left;
        font-family: "Lato", sans-serif;
        font-weight: normal;

        &:hover,
        &.sel-submenu {
          background: #dae7ee;
          border-radius: 3px;
          color: #047ec3;
        }
      }
    }
  }
}

.business-section {
  font-size: 14px;
  font-family: "Lato", sans-serif;
  color: #667680;
  font-weight: normal;
  float: right !important;
  width: 81% !important;
  min-height: 450px;
  margin-bottom: 50px;
  position: relative;
}

.mainRightActions {
  float: right;

  .easy-link {
    padding: 8px 7px 5px !important;

    svg {
      margin: 0px 5px;
    }
  }
}

.currentPageName {
  color: #7b8590;
}

.mainSectionTitle {
  float: left;
  width: 100%;
  padding: 15px;
  font-size: 13px;
  color: #000;
  border-bottom: 1px solid #dddddd;
}

.bold {
  font-weight: bold !important;
}

.trBold {
  &:hover {
    background: #ffffff !important;
  }

  td {
    font-weight: bold !important;
  }
}

.records-table {
  width: 100%;
  color: #000000;
  margin-bottom: 10px;

  th {
    font-weight: bold;
    padding: 15px;
    border-bottom: 1px solid #dddddd;
    font-size: 13px;
  }

  td {
    padding: 7px 15px;
    font-size: 13px;
  }

  .sub-record-table {
    padding-left: 15px;
    width: 100%;
  }

  tfoot td {
    font-weight: bold;
    padding: 15px;
    border-top: 1px solid #dddddd;
    font-size: 13px;
  }
}

.sale-table th {
  color: #667680 !important;
}

.sale-table td {
  color: #000000 !important;
}

.intl-tel-input input::placeholder {
  color: #aaa;
  font-weight: normal;
}

.intl-tel-input input::-ms-input-placeholder {
  color: #aaa;
  font-weight: normal;
}

.intl-tel-input input::-webkit-input-placeholder {
  color: #aaa;
  font-weight: normal;
}

.salesStats {
  width: 20%;
  display: table-cell;
  padding-right: 10px;
}

.sale-row {
  display: table;
  width: 100%;

  & > div:last-child {
    padding-right: 0px;
  }
}

.egiftCardGraph {
  margin: 0px 0 -44px;

  position: relative;

  top: 56px;

  .highcharts-container {
    display: inline-block;

    margin-top: -58px;
  }
}

.mainRightActions .easy-link {
  position: relative;
  cursor: pointer;
}

.actionDropdownOuter {
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 37px;
  width: 200px;
  border: 1px solid #ddd;
  @include border-radius(5px);
  @include box-shadow(4px, 4px, 8px, 0, #aaaaaa);
  background: #fff;

  ul.actionDropdown {
    width: 200px;
    height: auto;
    background: #fff;
    list-style: none;
    display: block;

    li {
      display: block;
      border-bottom: 1px solid #dddddd;

      a {
        padding: 10px 15px;
        color: #000000;
        font-size: 13px;
        display: block;
        word-break: break-all;

        &:hover,
        &.selected {
          font-weight: bold;
        }
      }

      &:last-child {
        border: none;
      }
    }
  }

  &:after {
    width: 15px;
    height: 15px;
    content: url(/../images/arrow-top.png);
    position: absolute;
    top: -13px;
    left: 15px;
  }
}

.sales-filter-cal {
  width: 150px;
}

.newTableTitle {
  font-size: 13px;
  display: inline-block;
  padding: 7px;
  color: #000000;
}

.auto-height {
  height: auto !important;
  min-height: inherit !important;
}

.AddBtn {
  right: -8px;
  top: 22px;
}

.customTaxRule {
  margin-top: -30px;

  .switch-accordian-row {
    border: none;
    padding-left: 80px;
    font-size: 13px;
    padding-top: 22px;

    .setting-switch {
      right: inherit;
      left: 30px;
    }
  }
}

.tableInputTax {
  width: 96px;
  float: left;
  color: #404040;
  border: 1px solid #cad2d6;
  border-radius: 3px;
  height: 33px;
  padding: 0px 15px;
  font-size: 14px;
  background: #ffffff;
}

.tableInput {
  width: 100%;
  float: left;
  color: #404040;
  border: 1px solid #cad2d6;
  border-radius: 3px;
  height: 33px;
  padding: 0px 15px;
  font-size: 14px;
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 10px auto;
}

.tableSelect {
  width: 143px;
  float: left;
  color: #404040;
  border: 1px solid #cad2d6;
  border-radius: 3px;
  height: 33px;
  padding: 0px 15px;
  font-size: 14px;
  background-image: url(/../images/select-arrow2.png);
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 10px auto;
}

.full-loader {
  z-index: 10;

  .loader-outer {
    margin-top: -85px;
    top: 50%;
  }
}

.newTableTitle {
  font-size: 13px;
  display: inline-block;
  padding: 7px;
  color: #000000;
}

.popup-loader {
  z-index: 10;
  position: fixed;

  .loader-outer {
    position: fixed;
    top: 50%;
    margin: 0px;
    margin-top: -100px;
  }
}

.newtextareaField {
  resize: none;
}

.intercom-launcher-frame {
  bottom: 44px !important;
}

.intercom-launcher-badge-frame {
  bottom: 90px !important;
}

.protected .newInputFileldOuter .tag-auto-select.field_error {
  border: 1px solid #e74c3c !important;
}

.addSaleSelect > div > div {
  padding-left: 5px;
}

.green-switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cccccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-color: #cccccc;
}

input:checked + .green-switch {
  background-color: #8cbd3f;
  border-color: #8cbd3f;
}

.tableCategoryDropDown {
  background: #e6eef2;
  position: absolute;
  width: 100%;
  top: 100%;
  padding: 10px 5px 0px 10px;
  @include border-radius(0px 0px 5px 5px);

  ul {
    list-style: none;

    li {
      color: #667680;
      font-size: 13px;
      display: block;
      line-height: 25px;
      cursor: pointer;
    }
  }
}

.tableArrowUp {
  line-height: 33px;
  top: 3px;
  position: relative;
  margin-left: 10px;
  cursor: pointer;
  color: #667680;
}

.tableArrowDown {
  line-height: 33px;
  top: -3px;
  position: relative;
  margin-left: 10px;
  cursor: pointer;
  color: #667680;
}

.removeLeftBorder {
  border-left: 0px !important;
}

.small-popup-outer {
  width: 580px;
  height: auto;
  margin: auto;
  background: #ffffff;
  @include border-radius(5px);
  margin-top: 90px;
  overflow: hidden;
  position: relative;

  .small-popup-header-2 {
    width: 100%;
    min-height: 45px;
    float: left;
    background: #c2e0f2;
    @include border-radius(0px 0px 0px 0px);
    padding: 0px 15px 0px 30px;
    position: relative;

    .go-back {
      float: left;
      color: #047ec3;
      font-size: 20px;
      font-weight: normal;
      margin-top: 8px;
      margin-right: 7px;
      position: absolute;
      left: 12px;
      background: none;
      border: none;
    }

    .popup-name-2 {
      font-size: 12px;
      text-transform: capitalize;
      line-height: 21px;
      color: #404040;
      vertical-align: top;
      float: left;
      padding: 13px 0px 0px;
    }

    .small-cross {
      color: #2c3e50;
      font-size: 23px;
      height: 20px;
      margin-top: 7px;
      float: right;
      left: inherit;
      right: 15px;
      position: absolute;
      opacity: 0.4;
      font-weight: 800;

      &:hover {
        opacity: 1;
      }
    }
  }

  .small-popup-content {
    float: left;
    width: 100%;
    max-height: 383px;
    overflow-y: auto;
    overflow-x: auto;
  }
}

.removeBackgroundColor {
  background-color: white !important;
}

.changedName {
  padding: 33px 0px 0px !important;
}

.new-blue-btn-2 {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  border: none;
  padding: 4px 17px 4px;
  height: 32px;
  font-size: 14px;
  color: #fff;
  background-color: #047ec3;
  border-radius: 3px;
  display: inline-block;
  vertical-align: top;
  min-width: 103px;
  text-align: center;
  margin-left: 0px !important;
  text-decoration: none !important;
  font-family: "Lato", sans-serif;
  line-height: 24px;
}

.no-float-left {
  float: none !important;
}

.newInputFileldOuter .hidePassword {
  right: 7px;
  top: 7px;
}

.setting-input-outer .flag-container .iti-arrow {
  right: inherit !important;
  left: 25px;
}

.wallet-switch {
  padding-right: 55px;
  position: relative;

  .setting-switch {
    position: absolute;
    right: 0px;
    top: 0px;
  }
}

.appointment-container .footer-static {
  padding: 15px 0px;
}

.appointment-container .switch-accordian-row .factor_status {
  margin-right: 5px;
}

.client-section {
  font-size: 14px;
  font-family: "Lato", sans-serif;
  color: #667680;
  font-weight: normal;
  float: right !important;
  width: 100% !important;
  min-height: 450px;
  margin-bottom: 50px;
  position: relative;
}

.fontSize18 {
  font-size: 18px !important;
  margin: 0px 8px !important;
}

.heightChange {
  height: 42px;
  font-size: 16px !important;
}

.usageSettingSection {
  table {
    border: none;
  }

  td {
    padding-left: 0px !important;
    padding-right: 0px !important;

    select {
      background: none;
      border: none;
      color: #047ec3;
      background-image: url(/images/new-down-arrow.png);
      background-repeat: no-repeat;
      background-position: right 9px;
      padding-right: 12px;
    }
  }
}

.usageTotal {
  color: #000000;
}

.usageSettingtitle {
  float: left;
  width: 100%;
  padding: 0px 30px;
}

.searchClientSMS {
  width: 100%;
  background: #f2f6f7;
  height: 32px;
  padding: 0px 10px;
  @include border-radius(5px);
  font-size: 14px;
}

.searchClientSMS::placeholder {
  color: #bbbbbb;
}

.searchClientSMS::-ms-input-placeholder {
  color: #bbbbbb;
}

.dateTimePicker .react-datepicker-popper {
  width: 347px;

  .react-datepicker__time-container,
  .react-datepicker__time-box {
    width: 100px !important;
  }
}

.displayNumber {
  float: right;
  font-weight: normal;

  .displayNumber__label {
    font-size: 13px;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 0px;
  }

  .displayNumber__number {
    font-size: 13px;
    font-weight: normal;
  }
}

.memberRightActions {
  .membership-title-right {
    font-size: 16px;
    margin-top: 5px;
  }
}

.div-searchClientSMS {
  .ul-searchClientSMS {
    top: 34px;
  }
}

.socialDropdownIcon {
  background-size: 14px auto;
  background-repeat: no-repeat;
  background-position: left 10px center;
  padding-left: 28px !important;
  padding-right: 10px !important;
}

.social-share {
  background-image: url(/../images/share.png);
  background-size: 14px auto;
  background-repeat: no-repeat;
  background-position: left 10px center;
  color: #ffffff;
  background-color: #047ec3;

  &:hover {
    background-image: url(/../images/share.png);
    background-size: 14px auto;
    background-repeat: no-repeat;
    background-position: left 10px center;
  }
}

.LeadAR {
  background-image: url(/../images/leadar.png);

  &:hover {
    background-image: url(/../images/leadar-hover.png);
  }
}

.Hubspot {
  background-image: url(/../images/hubsport.png);

  &:hover {
    background-image: url(/../images/hubsport-hover.png);
  }
}

.Mailchimp {
  background-image: url(/../images/mailchimp.png);

  &:hover {
    background-image: url(/../images/mailchimp-hover.png);
  }
}

.Intercom {
  background-image: url(/../images/intercom.png);

  &:hover {
    background-image: url(/../images/intercom-hover.png);
  }
}

.Zoho {
  background-image: url(/../images/zoho.png);

  &:hover {
    background-image: url(/../images/zoho-hover.png);
  }
}

.ConstantContact {
  background-image: url(/../images/constant-contact.png);

  &:hover {
    background-image: url(/../images/constant-contact-hover.png);
  }
}

.socialDropdownOuter {
  min-width: 115px;
}

.font-weight-6 {
  font-weight: 600 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

#main-chat-outer-2 {
  float: left;
  padding: 0px 0px;
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: 450px;
  width: 100%;
}

.backgroundGray {
  background-color: #f4f6f6;
}

.product-active span {
  margin-right: 0px !important;
}

.product-active .m-r-15 {
  margin-right: 10px !important;
}

.newInputFileldOuter .field-icon .simple-cal-icon {
  top: 8px !important;
}

.colorRed {
  color: red;
  font-weight: bold;
}

.colorGreen {
  color: #229954;
  font-weight: bold;
}

.simpleInput.textOnly {
  padding: 7px 10px 0px;
  overflow: hidden;
  position: relative;
}

.verticalBtn {
  margin-top: 15px;
  padding: 6px 17px 5px;
  height: 36px;
}

.inputDoller {
  position: absolute;
  right: 7px;
  top: 7px;
  background: #ffffff;
  z-index: 1;
}

.inputDollerText {
  display: block;
  overflow: hidden;
  margin-right: 8px;
}

.buyNumberTitle span {
  width: auto !important;
}

.buyNumberTitle .filter-type {
  float: left !important;
  margin-left: 10px;
}

.inboxSetupCountry {
  display: inline-block !important;
  position: relative;
  margin-top: 6px;
  margin-left: 20px;

  .easy-link {
    cursor: pointer;
  }
}

.inboxSetupCountrydropdown.actionDropdownOuter {
  margin-top: 10px;

  &:after {
    top: -16px;
  }

  ul li a.selected {
    font-weight: bold;
  }

  ul li a:hover {
    background: none;
  }
}

.height38px {
  height: 38px !important;
}

.clientChatInfo {
  padding-left: 22px;
  position: relative;
  font-size: 13px;
  margin-bottom: 15px;
  word-break: break-word !important;
  float: left;
  width: 100%;
  min-height: 15px;

  svg {
    position: absolute;
    left: 0px;
  }
}

.pro-pic-outer.clientChatPicOuter {
  padding-left: 136px !important;
  min-height: 116px !important;

  img {
    max-width: 90px !important;
  }
}

.ChatInfoRowContainer {
  .ChatInfoRow {
    width: 100%;
    float: left;
    font-size: 13px;
    font-family: "Lato", sans-serif;
    color: #667680;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    display: flex;
    flex-wrap: nowrap;
    padding: 7px 0px;
    border-bottom: 1px dotted #d5d5d5;

    label {
      font-size: 13px;
      color: #404040;
      min-width: 185px;
      margin: 0px;
    }

    &:last-child {
      border: none;
    }
  }
}

.newInputFileldOuter .react-datepicker-popper {
  margin-top: 0px !important;
  top: 45px !important;
}

.simpleDatePicker .react-datepicker-popper {
  margin-top: 35px !important;
}

.min-width-180 {
  min-width: 180px !important;
}

.min-width-90 {
  min-width: 90px !important;
}

.min-width-200 {
  min-width: 200px !important;
}

.product-active span {
  margin-right: 0px !important;
}

.AuthorizedSync {
  display: block;
  float: left;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 600;

  svg {
    font-size: 17px;
    display: inline-block;
    vertical-align: text-bottom;
    color: #228c22;
    margin-right: 2px;
  }
}

.warning {
  svg {
    font-size: 17px;
    display: inline-block;
    vertical-align: text-bottom;
    color: #e52817;
    margin-right: 2px;
  }
}

.radioLabel {
  display: inline-block;
  margin-top: 2px;
}

.hide-password-clients {
  position: absolute;
  right: 19px;
  top: 21px;
  background: #fff;
  width: 21px;
  text-align: right;
}

.client-treat-cal-2 {
  position: absolute;
  right: 24px;
  top: 24px;
  color: #047ec3;
  cursor: pointer;
  z-index: 2;
}

.payasgo .add-round-btn {
  top: 24px;
}

.payasgo .newSelectField {
  padding-left: 10px !important;
}

.monthlyPlan .add-round-btn {
  top: 18px;
  right: -30px;
}

.featuresSection {
  padding: 20px;

  h4 {
    color: #000000;
    display: block;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .planFeatures {
    list-style: none;

    li {
      display: block;
      position: relative;
      padding: 6px 20px 6px 30px;
      margin-bottom: 5px;
      @include border-radius(3px);

      svg.fa-caret-right {
        position: absolute;
        right: 9px;
        top: 9px;
        color: $blue;
      }

      svg.fa-caret-down {
        position: absolute;
        right: 9px;
        top: 9px;
        color: $blue;
      }

      svg.fa-check {
        position: absolute;
        left: 9px;
        top: 9px;
        color: #34ad51;
      }

      svg.fa-times {
        position: absolute;
        left: 9px;
        top: 7px;
        color: #d02a33;
        font-size: 17px;
      }

      &:nth-child(odd) {
        background: #edf8ff;
      }

      &:nth-child(even) {
        background: #f9fcfe;
      }

      ul {
        margin-top: 10px;

        li {
          padding: 0px;
          padding: 0px 10px 0px;
        }
      }
    }
  }
}

.product-active span {
  margin-right: 0px !important;
}

.AuthorizedSync {
  display: block;
  float: left;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 600;

  svg {
    font-size: 17px;
    display: inline-block;
    vertical-align: text-bottom;
    color: #228c22;
    margin-right: 2px;
  }
}

.radioLabel {
  display: inline-block;
  margin-top: 2px;
}

.min-width-180 {
  min-width: 180px !important;
}

.min-width-90 {
  min-width: 90px !important;
}

.newTabsOuter {
  float: left;
}

.full-fixed-loader {
  z-index: 9999 !important;
}

.greenTick {
  color: #239b56 !important;
}

.payasgo .add-round-btn {
  top: 24px;
}

.payasgo .newSelectField {
  padding-left: 10px !important;
}

.monthlyPlan .add-round-btn {
  top: 18px;
  right: -30px;
}

.searchPageName {
  display: inline-block;
  font-size: 20px;
  color: #404040;
  vertical-align: top;
  margin-right: 10px;
  float: left;
}

.new-add-round {
  top: 25px !important;
}

.form-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 13px;
  margin-bottom: 20px;

  .formLabel {
    width: 125px;
    min-width: 125px;
    font-weight: 400;
    color: #667680;
    word-break: break-word;
    font-size: 13px;
    padding-top: 8px;
  }

  .formInputOuter {
    color: #000000;
    word-break: break-word;
    width: 100%;
  }
}

.detail-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 13px;
  margin-bottom: 10px;

  label {
    width: 100px;
    min-width: 100px;
    font-weight: 400;
    color: #404040;
    font-weight: 600;
    word-break: break-word;
  }

  span {
    color: #667680;
    word-break: break-word;
  }
}

.clockinOuter {
  display: table;
  overflow: visible;

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker-popper {
    margin-top: 35px !important;
  }

  .small-popup-content {
    overflow: visible;
  }

  .clock-time-picker .react-datepicker__time-container {
    width: 100px !important;

    .react-datepicker__time-box {
      width: 100%;
    }

    .react-datepicker__header {
      display: none;
    }

    .react-datepicker__time-list li {
      height: auto !important;
      border-bottom: 1px solid #dddddd;
      padding: 9px 10px;
    }
  }
}

.inlineTextInput {
  float: left;
  width: 100%;
  margin-bottom: 15px;
  color: #404040;

  & > span {
    display: inline-block;
    vertical-align: middle;
    margin-top: 6px;
  }

  .simpleField {
    width: 60px;
    display: inline-block;
    float: none;
    margin: 0px 5px;
    vertical-align: middle;

    .simpleLabel {
      margin-left: 5px;
    }
  }
}

.pay-period-divider {
  border-top: 1px solid #dedede;
  margin-top: 6px;
  padding-top: 10px;
}

.guidanceDetail {
  font-size: 13px;
  display: block;

  .juvly-subtitle {
    margin-bottom: 8px;
    color: #404040;
  }

  .guidanceDetailSteps {
    display: block;
    float: left;
    width: 100%;

    li {
      display: block;
      margin-bottom: 5px;
      list-style: circle;
      padding-left: 12px;
      position: relative;

      &:after {
        position: absolute;
        content: "•";
        left: 0px;
        top: 0px;
        line-height: 16px;
      }

      a {
        padding: 1px !important;
      }
    }
  }
}

.form-control[readonly] {
  background: none !important;
}

.titleDropDown {
  display: inline-block;
  padding-right: 18px;
  cursor: pointer;
  position: relative;
  margin-right: 5px;

  svg {
    position: absolute;
    right: 0px;
    top: 10px;
  }

  .actionDropdownOuter {
    width: 150px !important;

    ul {
      margin: 0px !important;

      li a {
        padding: 5px 15px !important;

        &:hover {
          font-weight: normal;
          background: $blue;
          color: #ffffff;
        }
      }
    }

    &:after {
      top: -22px;
      left: initial;
      right: 11px;
    }
  }
}

.contactPersonBorder {
  margin-bottom: 15px;
  border-bottom: 1px dashed #dddddd;
}

.proQuesAccordion {
  float: left;
  width: 100%;
  padding: 15px;

  .survey-qus-ans {
    padding: 10px 0px !important;

    .survey-ques {
      margin-bottom: 0px !important;
      font-size: 13px !important;
    }

    .survey-ans {
      font-size: 13px !important;
    }
  }

  & > div > div:last-child {
    background: none;
  }
}

.proQuesAccordionTitle {
  background: #c2e0f2;
  font-size: 14px;
  padding: 10px 15px;
  color: #404040;
  @include border-radius(3px 3px 0px 0px);
}

.proQuesAccordionOuter {
  box-shadow: inherit !important;
  border: 1px solid #dddddd;
}

.presc-container {
  width: 100% !important;
  margin: 50px auto 0;
  float: none !important;
  padding: 0px !important;
}

.position_fixed {
  position: fixed;
}

.bottomBorder {
  border-bottom: 1px solid #dddddd;
}

.fontSize14px {
  font-size: 14px !important;
}

.easy-link.text-success,
svg.text-success {
  color: #5cb85c !important;
}

.easy-link.text-danger,
svg.text-danger {
  color: #d0021b !important;
}

#accordian {
  width: 100%;
  float: left;
}

#accordian h3 {
  width: 100%;
  position: relative;
  font-size: 14px;
  color: #404040;
  margin: 0px;
  position: relative;
  border-bottom: 1px solid #dddddd;
}

#accordian .operational,
#accordian .inactive,
#accordian .non-operational {
  position: absolute;
  top: 8px;
  right: 20px;
}

#accordian h3 a {
  padding: 0px;
  font-size: 15px;
  display: block;
  color: #404040;
  text-decoration: none;
  padding-left: 20px;
  padding: 17px 20px 18px 36px;
}

#accordian h3 a span {
  position: absolute;
  right: 20px;
  top: 18px !important;
}

#accordian h3 a:after {
  content: "+";
  position: absolute;
  left: 16px;
  top: 9px;
  font-size: 27px;
}

#accordian .active h3 a:after {
  content: "_";
  position: absolute;
  left: 16px;
  top: -2px;
  font-size: 27px;
}

#accordian h3:hover {
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.7);
}

i {
  margin-right: 10px;
}

#accordian li {
  list-style-type: none;
}

#accordian ul ul li a,
#accordian h4 {
  text-decoration: none;
  font-size: 11px;
  line-height: 27px;
  display: block;
  padding: 0 20px;
  transition: all 0.15s;
  position: relative;
}

#accordian ul ul li a {
  font-size: 12px;
  color: #677680;
}

#accordian ul ul ul li a {
  font-size: 13px;
  color: #404040;
  line-height: inherit;
  position: relative;
  padding-right: 100px;
  padding: 7px 100px 7px 55px;
}

#accordian ul ul {
  display: none;
}

#accordian li.active > ul {
  display: block;
}

#accordian ul ul ul {
}

#accordian a:not(:only-child):after {
  content: "\f107";
  font-family: fontawesome;
  position: absolute;
  left: 20px;
  top: 10px;
  font-size: 14px;
}

#accordian .active > a:not(:only-child):after {
  content: "\f106";
}

#accordian > ul > li {
  background: #fff;
  float: left;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  padding: 0px;
  margin-bottom: 10px;
}

#accordian > ul > li > ul {
  border-top: 1px solid #dddddd;
}

#accordian > ul > li > ul > li > a {
  padding: 10px 20px;
  padding-left: 39px;
  font-size: 14px;
}

#accordian > ul > li > ul > li {
  border-bottom: 1px dotted #dddddd;
}

#accordian > ul > li > ul > li > ul {
  padding-bottom: 10px;
}

.list-all-questionnaire {
  margin-bottom: 50px;
  float: left;
  width: 100%;
}

.covidCommunication {
  .select-fill-box {
    background: url(/../images/arrow.png) no-repeat right 5px center;
    padding: 3px 19px 3px 3px;
    margin: 0px 3px;
    background-size: auto 8px;
    display: inline-block;
  }

  .covidCol {
    display: inline-block;
    vertical-align: top;
  }

  .covidColOuter {
    margin-bottom: 15px;

    .covid-scheduling {
      .covid-scheduling-view {
        width: 260px;
      }

      .covid-scheduling-edit {
        width: 340px;
      }
    }
  }

  .covidColAciton {
    margin-left: 10px;
  }

  .covidFilledView {
    border: none;
    background: none;
    padding-right: 0px;
    width: auto;
    text-align: center;
    display: inline-block;
  }
}

.width800 {
  width: 800px;
  border: 1px solid #e6e9eb;
}

.marginSet {
  margin-top: -10px;
}

.bordersides {
  border: 1px solid #e6e9eb;
  padding: 20px;
}

.text-capitalize {
  text-transform: capitalize;
}

.cursor-default {
  cursor: default !important;
}

.treatmentPlan.e-Prescribe {
  width: 98% !important;
  height: 99% !important;
}

.treatmentPlan.e-Prescribe iframe {
  max-width: 100%;
  max-height: 100%;
}

.eprescribeHeaderImg {
  height: 35px;
  margin-right: 16px;
  display: inline-block;
  vertical-align: top;
}

.infoIcon svg {
  display: inline-block !important;
  vertical-align: top !important;
  margin-top: 7px !important;
  font-size: 16px !important;
  margin-right: 6px !important;
  color: $blue;
  cursor: pointer;
}

.workspace-verified {
  margin-top: 200px;

  .workspace-verified-inner {
    width: 320px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    input {
      display: block;
      width: 100%;
      height: 45px;
      padding: 10px 15px;
      font-size: 15px;
      line-height: 1.42857143;
      color: #2c3e50;
      background-color: #ffffff;
      background-image: none;
      border: 1px solid #aaa;
      border-radius: 4px;
    }

    a {
      padding: 0;
    }

    label {
      margin: 0;
      margin-bottom: 10px;
      margin-top: 10px;
      font-size: 16px;
      font-weight: normal;
      text-align: left;
    }

    button {
      margin: 0;
      margin-top: 20px;
      align-self: center;
      font-size: 15px;
      height: 45px;
    }
  }
}

.flex-column {
  flex-direction: column !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.w-450 {
  width: 450px;
}

.min-w-100 {
  min-width: 100px;
}

.min-w-200 {
  min-width: 200px;
}

.colorAR {
  color: #047ec3 !important;
}

.field-note {
  float: left;
  width: 100%;
  color: #667680;
  font-size: 10.5px;
  margin-top: 3px;
}

.container {
  max-width: 50%;
  margin: 40px auto;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;

  &:before {
    content: "";
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }

  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 0.5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: #818078;
    background-color: #fcfcfa;
  }
}

.creatableZIndex {
  z-index: 2;
}

.setLineHeight {
  line-height: 50px !important;
  letter-spacing: 0.5px;
}

.drawerRow {
  .newInputLabel,
  input,
  select {
    background: #f7fbfd !important;
  }
}

.viewModeSection .newInputField {
  padding-top: 7px !important;
  background: none !important;
  font-weight: normal !important;
}

.fontsize16 {
  font-size: 16px !important;
}

.setPadding0 {
  padding: 20px 0px !important;
}

.protected .CalendarOuter .calendarrightSection .rbc-header div {
  margin-top: -7px;
  font-size: 12px;
  color: #666666;
  line-height: 15px;
}

.protected .CalendarOuter .calendarrightSection .resource_schedule {
  height: 104px !important;
}

.commitmentOuter {
  float: left;
  width: 100%;
  text-align: center;
}

.commitmentSection {
  border: 1px solid $blue;
  padding: 17px 20px 20px;
  display: inline-block;
  margin-bottom: 30px;
  width: 40%;
  text-align: left;
  @include border-radius(5px);
  margin: 20px 1% 50px;
  background: #f9fafb;
  vertical-align: top;

  &:hover,
  &.selected {
    border: 1px solid #5cb85c;
    cursor: pointer;
    background: #f3fdf3;

    button {
      background: #5cb85c;
    }
  }

  .commitmentTitle {
    display: block;
    text-align: center;
    font-size: 18px;
    color: #404040;
    margin-bottom: 20px;
  }

  .commitmentRow {
    display: block;
    background: #ffffff;
    padding: 10px 20px;
    @include border-radius(5px);
    @include box-shadow(0, 0px, 7px, 0, rgba(0, 0, 0, 0.05));
    margin-bottom: 5px;

    h1 {
      font-size: 14px;
      color: #404040;
      margin-bottom: 5px;
    }

    p {
      font-size: 13px;
      color: #667680;
      font-style: italic;
      margin: 0px;
    }
  }

  .commitmentTotalDue {
    display: block;
    font-size: 14px;
    color: #404040;
    font-weight: bold;
    margin-top: 15px;
    padding: 0px 20px;
    text-align: center;
  }

  .commitmentBtn {
    display: block;
    text-align: center;
    margin-top: 28px;
    margin-bottom: -37px;

    button {
      margin: 0px;
    }
  }
}

.confirmEnrollOuter {
  padding: 50px 20px 20px;
  text-align: center;
  display: block;
  border: 1px solid #cad2d6;
  @include border-radius(5px);
  margin-top: 47px;
  min-height: 226px;
  background: #fafafa;
}

.eprescriptionDropdown {
  z-index: 2;
}

.detail-row.agentPlan label {
  width: 135px;
}

.success-msg {
  text-align: center;
  margin: 20px 10% 0px;

  .successIcon {
    width: 50px;
    height: 50px;
    display: inline-block;
    border: 2px solid #0075c0;
    font-size: 24px;
    color: #0075c0;
    @include border-radius(50px);
    padding: 8px;
    margin-bottom: 13px;
  }

  h2 {
    font-weight: bold;
    margin-bottom: 16px;
    font-size: 28px;
  }

  p {
    margin: 10px 0px 0px;
  }
}

.initial-setup-text {
  margin: 20px;
  color: #000;
}

.enroll-user-outer {
  border: 1px solid #dddddd;
  @include border-radius(5px);
  padding: 20px;
  min-height: 150px;
  text-align: center;
  display: flex;
  align-items: center;
  background: #f9fafb;
  border: 1px solid $blue;

  * {
    cursor: pointer;
  }

  &:hover,
  &.selected {
    border: 1px solid #5cb85c;
    cursor: pointer;
    background: #f3fdf3;
    cursor: pointer;
  }
}

.__react_component_tooltip {
  width: 300px !important;
  word-break: break-word;
}

.colorAR {
  color: #047ec3;
}

.card-fields {
  letter-spacing: 2px;
  font-size: 16px;
  padding-top: 0px !important;
}

#clearent-payment-errors.clearent-payment-errors {
  color: #e52817 !important;
  display: none !important;
}

.ePrescribeBtn {
  padding: 4px 17px 4px !important;
  float: right !important;
  margin-top: 8px !important;
}

.detailedProductLogsCal {
  left: inherit !important;
  right: 0px !important;
}

.wallet-cherry-action-button > img {
  max-height: 38px;
}

.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.grow {
  flex-grow: 1;
}

.items-center {
  display: flex !important;
  align-items: center !important;
}

.items-end {
  align-items: flex-end;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.common-modal-body {
  padding: 15px 25px 20px 25px;
  margin: auto;
  max-height: 90vh;
  display: flex !important;
  flex-direction: column;
}

@media (max-width: 767px) {
  .common-modal-body {
    padding: 0px;
  }
}

.common-modal-header {
  padding: 0px !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;

  min-height: 30px;

  .small-cross {
    margin-top: 0px !important;
    height: auto !important;
  }
}

.common-modal-footer {
  border-top: 1px solid #d0d5dd;
  padding: 12px 30px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.common-modal-content {
  font-family: $opensan;
}

.primary-modal-styles {
  .common-modal-body {
    padding: 0px;

    .common-modal-header {
      background-color: #c2e0f2 !important;
      padding: 15px 35px !important;
    }

    .common-modal-content {
      padding: 15px 35px !important;
    }
  }

  .common-modal-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.filled-checkbox {
  border-radius: 6px !important;
  transform: scale(1.18);
  margin-top: 0px !important;
  border: 1.5px solid $color-border-gray !important;
}

.filled-checkbox:checked {
  border: none !important;
  transform: scale(1.3);
  content: url(../../_legacy/images/filled-checkbox.svg);
  display: block;
  top: -1px !important;
  left: 1px;
  outline: none;
  vertical-align: top;
}

.disableView {
  cursor: not-allowed;
  color: #9ac4f8;
  font-size: 13px;
  font-weight: 400;
}

.cursor-pointer {
  cursor: pointer !important;
}

// Scroll bar
::-webkit-scrollbar {
  width: 6px;
  scrollbar-width: thin;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  width: 6px;
  scrollbar-width: thin;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: $color-border-gray;

  border-radius: 20px;
  width: 6px;
  background-clip: content-box;
  height: 3px;
}

::-webkit-scrollbar-thumb:hover {
  width: 6px;
  height: 3px;
}

::-webkit-scrollbar-thumb:horizontal {
  scrollbar-width: thin;
}

.breadcrumbs {
  font-family: $opensan;
  font-weight: 300;
  font-size: 20px;
  // line-height: 24px;
  gap: 6px;

  a {
    color: black;
    font-weight: 600;
  }

  .right-icon {
    height: 12px;
    margin: auto 4px;
  }
}

.responsive-modal {
  width: 100vw;
  height: 100vh;
}

.common-dialog {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  overflow-x: auto;

  .large-popup {
    width: 720px !important;
  }

  .modal-title {
    h4 {
      font-weight: 600;
      font-size: 20px !important;
    }
  }

  .small-cross {
    width: 14px !important;
    opacity: 1 !important;
    cursor: pointer !important;
  }
}

.table-action-button {
  background: transparent;
  border: none;
  font-size: 16px;
  color: #188bf6;

  img {
    margin-right: 12px;
  }
}

.break-word {
  word-break: break-word;
}

hr.divider {
  border: 1px solid rgba(0, 0, 0, 0.16);
  width: 100%;
  margin: 0px auto 16px;
}

.rotate-180 {
  transform: rotate(180deg);
}

.bg-red {
  background-color: #c14d4d;
}

.p-h-20 {
  padding: 0 20px;
}

.mce-floatpanel {
  z-index: 999999 !important;
}

.link-btn {
  background: none;
  border: none;
  color: $blue;
  text-decoration: underline;
}

.btn-reset {
  background: none;
  border: none;
  outline: none;
}

.color-error {
  color: $error;

  &:hover {
    color: darken($error, 4%) !important;
  }
}

.text-color-red {
  color: $error !important;
}

.text-color-green {
  color: green !important;
}

.p-h-20 {
  padding: 0 20px;
}

.bg-red {
  background-color: #c14d4d;
}

.w-max {
  width: max-content;
}

.disabled {
  pointer-events: none;
  user-select: none;
}

.buttonDisabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.6;
}

.pos-top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.read-more {
  color: #1172ce;
  cursor: pointer;
}

.confirm-modal-center {
  top: 50% !important;
  transform: translate(0, -50%);
  max-height: 90%;
}

.h-auto {
  height: auto !important;
}

.word-wrap-break {
  word-wrap: break-word !important;
}

.margin-0 {
  margin: 0 !important;
}

.top--10 {
  top: -10px !important;
}

.text-no-wrap {
  white-space: nowrap !important;
}

.lineThrough {
  text-decoration: line-through;
}

.max-w-30 {
  max-width: 30px !important;
}

.max-w-100 {
  max-width: 100px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.setting-title-default {
  font-size: 20px;
}

.font-16 {
  font-size: 16px !important;
}

.font-24 {
  font-size: 24px !important;
}

.react-datepicker__close-icon::after {
  background-color: #188bf6 !important;
}

.md-room-back-div {
  margin-left: auto;
}

.back-arrow-md-room {
  align-items: center;
  color: #188bf6;
  gap: 4px;
}

.md-room-link {
  color: inherit !important;
  font-size: 15px !important;
  font-weight: bold !important;
  cursor: pointer;
  padding-left: 2px !important;
  text-decoration: none;
}

body.mce-notification-warning,
.mce-notification-warning,
body.mce-notification,
.mce-notification,
body.mce-widget,
.mce-notification-inner {
  display: none !important;
  width: 0px !important;
  z-index: -100 !important;

  button,
  div {
    display: none;
  }
}

.vertical-align-initial {
  vertical-align: initial !important;
}

.overflow-auto {
  overflow: auto !important;
}

.w-fit {
  width: fit-content !important;
}

.disabled-easy-link {
  color: #8b8b8b !important;
  pointer-events: none;
  cursor: default;
}

.m-0 {
  margin: 0 !important;
}

.m-0-auto {
  margin: 0 auto !important;
}

.z-index-9 {
  z-index: 9 !important;
}

.z-index-10 {
  z-index: 10 !important;
}

.p-0 {
  padding: 0 !important;
}

.p-x-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.whitespace-pre-wrap {
  white-space: pre-wrap !important;
}

.white-popup-override {
  width: 680px;
  background: #ffffff;
  border-radius: 5px;
  padding: 13px;
  overflow: hidden;
}

.preview-title-format {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  margin-bottom: 10px;
}

.preview-content-center {
  display: flex;
  margin-top: 2%;
  overflow: hidden;
  transform: translateX(-90px);
}

.text-italic {
  font-style: italic !important;
}

@media (max-width: 800px) {
  .white-popup {
    width: auto;
  }
}

.main-admin-star {
  color: #ed0058;
}

.top-0 {
  top: 0 !important;
}

.top--4 {
  top: -4px !important;
}

.inline-table {
  display: inline-table !important;
}

.p-r-117 {
  padding-right: 117px !important;
}

.opacity-0-7 {
  opacity: 0.7;
}

.loyalty-block {
  display: flex;
  padding: 15px 0;
  justify-content: space-between;
  max-width: 224px;
}

.loyalty-title {
  font-family: $lato;
  font-size: 15px;
}

.modal-header-content {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 455px) {
  .w-sm-100 {
    width: 100%;
  }

  .modal-footer-buttons-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.w-full {
  width: 100% !important;
}

@import "../../../styles/theme.scss";

.msgLimit {
  padding: 0 10px 5px 10px;
  font-size: 12px;
  margin-top: -5px;
  color: $cGray2;
}

.patientName {
  color: black;
  font-size: 16px;
  text-decoration: none;
}

.patientName:hover {
  text-decoration: underline;
}

@import "../../../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  padding-bottom: 25px;

  .required {
    color: $cRed;
  }

  .btnContinue {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    max-width: 800px;
  }

  .formLabel {
    font-size: 16px;
    font-family: lato, "sans-sarif";
  }

  .domainForm {
    display: flex;
    flex-direction: column;
    max-width: 800px;
  }

  .error {
    color: $cRed;
    font-size: 14px;
    padding: 10px 0;
  }
}

@import "../../../../../../styles/theme.scss";

.root {
  padding: 30px 0;
  border-bottom: 1px solid $cGray3;
}

.infoWrapper {
  display: flex;
  gap: 15px;
  margin-top: 30px;
  font-family: lato, "sans-sarif";
}

.info {
  margin-top: 10px;
}

.infoText {
  font-size: 14px;
  font-family: lato, "sans-sarif";
}

.txt {
  max-width: 100px;
}

.infoTitle {
  font-weight: bold;
  margin-bottom: 10px;
}

.videoLink {
  font-size: 15px;
  color: $cBlue;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
}

.bottomBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;

  .bottomButtonsWrapper {
    display: flex;
    gap: 10px;
  }
}

.subdomainName {
  padding: 15px 0;
  font-size: 16px;
}

@media screen and (max-width: 550px) {
  .infoWrapper {
    flex-direction: column;
  }

  .bottomBlock {
    flex-direction: column;
    justify-content: baseline;
    gap: 10px;
  }

  .bottomButtonsWrapper {
    flex-direction: column;
  }
}

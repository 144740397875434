.footerContainer {
    display: flex;
    gap: 16px;

    button {
        flex: 1;
    }
}

.modalContent {
    display: grid;
    grid-template-columns: 48px 150px 48px 250px;
    column-gap: 48px;
    @media  screen and (max-width: 425px) {
        grid-template-columns: 48px 150px;
    }
}

.taxableCheckbox {
    height: 34px;
}
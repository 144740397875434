@import "../../../../../styles/theme.scss";

.tableHeader {
  background: $cGray4;
  color: $cGray0Dark;
}

.root {
  margin-top: 45px;
}

.tableWrapper {
  overflow: auto;

  .table {
    margin-top: 25px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
}

.verified {
  color: $cGreenDark;
  background: $cGreenDim;
  padding: 5px 10px;
  border-radius: 15px;
}

.verified::before {
  content: "•";
  color: $cGreenDark;
  font-size: 1em;
  margin-right: 8px;
}

.inactive {
  color: $cRedDark;
  background: $cRedDim3;
  border-radius: 15px;
  padding: 5px 10px;
}

.inactive::before {
  content: "•";
  color: $cRedDark;
  font-size: 1em;
  margin-right: 8px;
}

.pending {
  color: $cOrangeDark;
  background: $cOrangeDim;
  border-radius: 15px;
  padding: 5px 10px;
}

.pending::before {
  content: "•";
  color: $cOrangeDark;
  font-size: 1em;
  margin-right: 8px;
}

.actionButtons {
  display: flex;
  gap: 10px;

  .actionButton {
    color: $cGray0Dark;
  }
}
